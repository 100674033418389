import {
	Button,
	CloseButton,
	Group,
	List,
	Modal,
	Select,
	Stack,
	Text,
} from '@mantine/core';
import ModalTitle from '../../Titles/ModalTitle';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	Collection,
	SpecialCollections,
	addMeetingToCollectionModalOpenedAtom,
	bulkDeletingCollectionMeetingsSelection,
	meetingBeingAddedToCollection,
	meetingCollectionIDsUpdatedToggleAtom,
	meetingsBeingAddedToCollection,
} from '../../../Atoms/collections';
import { useCollections } from '../../../customHooks/useCollections';
import { Dispatch, SetStateAction, useState } from 'react';
import { currentUser } from '../../../Atoms/userAtoms';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { getCollectionContent } from '../../../api/collections';
import { currentMeeting, reloadMeetingsAtom } from '../../../Atoms/meetingAtom';

export default function MeetingToAddToCollectionModal() {
	const user = useRecoilValue(currentUser);
	const [meetingToAddToCollection, setMeetingToAddToCollection] =
		useRecoilState(meetingBeingAddedToCollection);
	const [meetingsToAddToCollection, setMeetingsToAddToCollection] =
		useRecoilState(meetingsBeingAddedToCollection);
	const setSelection = useSetRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [
		addMeetingToCollectionModalOpened,
		setAddMeetingToCollectionModalOpened,
	] = useRecoilState(addMeetingToCollectionModalOpenedAtom);
	const {
		collections,
		setCollections,
		removeMeetingFromCollection,
		addMeetingsToCollection,
	} = useCollections();
	const [selectedCollection, setSelectedCollection] = useState('');
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const setMeetingCollectionsUpdated = useSetRecoilState(
		meetingCollectionIDsUpdatedToggleAtom
	);
	const [reloadMeetings, setReloadMeetings] =
		useRecoilState(reloadMeetingsAtom);

	const collectionData = (collections || [])
		.filter((collection) => {
			if (collection.name === SpecialCollections.Archived) return false;
			const collectionIDs = meetingToAddToCollection?.collectionIDs ?? [];
			return (
				collection.owningUserID === user.id &&
				!collectionIDs.includes(collection?.id)
			);
		})
		.map((collection) => ({
			value: collection.id,
			label: collection.name,
		}));

	const [myCollections, collectionsSharedWithMe] = (
		Array.isArray(meetingToAddToCollection?.collectionIDs)
			? meetingToAddToCollection.collectionIDs
			: []
	).reduce(
		(prev, cur) => {
			const collection = collections.find(
				(collection) => collection.id === cur
			);
			if (collection) {
				if (collection.owningUserID === user.id) {
					prev[0].push(collection);
				} else {
					prev[1].push(collection);
				}
			}
			return prev;
		},
		[[], []]
	);

	const closeModalAndClearSelect = () => {
		setAddMeetingToCollectionModalOpened(false);
		setMeetingsToAddToCollection([]);
		setSelectedCollection('');
	};

	const handleAdd = async () => {
		try {
			if (!selectedCollection) {
				showFailureNotification({
					message: 'Please select a collection before adding.',
				});
				return;
			}
			if (!meetingToAddToCollection) {
				showFailureNotification({
					message: 'No meeting selected to add to collection.',
				});
				return;
			}
			setButtonLoading(true);
			const newCollectionContent = await addMeetingsToCollection(
				selectedCollection,
				[meetingToAddToCollection.id]
			);
			if (newCollectionContent.length) {
				const updatedCollections = collections.map((collection) => {
					if (collection.id === selectedCollection) {
						return {
							...collection,
							content: [...(collection.content || []), ...newCollectionContent],
						};
					}
					return collection;
				});
				const newCollectionIDs = newCollectionContent.map(
					(c) => c.collectionID
				);
				setCollections(updatedCollections);
				setMeeting((prev) => ({
					...prev,
					collectionIDs: [...(prev?.collectionIDs || []), ...newCollectionIDs],
				}));
				setMeetingToAddToCollection((prev) => ({
					...prev,
					collectionIDs: [...(prev?.collectionIDs || []), ...newCollectionIDs],
				}));
				setMeetingCollectionsUpdated((prev) => !prev);
			}

			// add content to content of that collection
			closeModalAndClearSelect();
			if (reloadMeetings) setReloadMeetings(!reloadMeetings);
			showSuccessNotification({
				message: 'Meeting successfully added to collection!',
			});
		} catch (error) {
			logger('error', 'Error adding meeting to collection', error);
		} finally {
			setButtonLoading(false);
		}
	};

	const bulkAddMeetingsToCollection = async () => {
		try {
			setButtonLoading(true);
			const newlyAddedMeetings = await addMeetingsToCollection(
				selectedCollection,
				meetingsToAddToCollection.map((m) => m.id)
			);
			closeModalAndClearSelect();
			if (reloadMeetings) setReloadMeetings(!reloadMeetings);
			setSelection([]);
			showSuccessNotification({
				message: 'Meetings successfully added to collection!',
			});
		} catch (error) {
			logger('error', 'Error adding meeting to collection', error);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully added to the collection. Please try again.`,
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const handleRemove = async (collection: Collection, meetingID: string) => {
		try {
			const content = await getCollectionContent(collection.id);
			const deletedCollectionID = await removeMeetingFromCollection(
				{ ...collection, content },
				meetingID
			);
			setMeetingToAddToCollection((prev) => ({
				...prev,
				collectionIDs: prev?.collectionIDs?.filter(
					(id) => id !== deletedCollectionID
				),
			}));
			if (reloadMeetings) setReloadMeetings(!reloadMeetings);
			setMeetingCollectionsUpdated((prev) => !prev);
		} catch (err) {
			logger('error', 'Error removing meeting from collection', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully removed from the collection. Please try the operation again.`,
			});
		}
	};

	return (
		<Modal
			withinPortal={false}
			size={'sm'}
			zIndex={1000}
			centered={true}
			title={<ModalTitle text={'Add to Collection'} />}
			xOffset={300}
			styles={{
				inner: {
					position: 'absolute',
					right: 0,
					top: -300,
				},
			}}
			opened={addMeetingToCollectionModalOpened}
			onClose={closeModalAndClearSelect}
		>
			<Stack mt={'md'}>
				{meetingsToAddToCollection.length ? (
					<Stack spacing={'xs'}>
						<Text>Meetings</Text>
						<List
							size={'sm'}
							styles={{
								itemWrapper: {
									width: '100%',
								},
							}}
						>
							{meetingsToAddToCollection.map((meeting) => {
								return (
									<List.Item key={meeting.id}>
										<Group position='apart' noWrap mr={17}>
											<Text>{meeting.name}</Text>
										</Group>
									</List.Item>
								);
							})}
						</List>
					</Stack>
				) : null}
				<Select
					value={selectedCollection}
					onChange={setSelectedCollection}
					placeholder='Select collection'
					data={collectionData}
					searchable
					nothingFound='Nothing found...'
				/>
				<Group noWrap position={'right'} spacing={'xs'}>
					<Button
						variant='subtle'
						radius='md'
						onClick={closeModalAndClearSelect}
						px={'sm'}
						disabled={buttonLoading}
					>
						Cancel
					</Button>
					<Button
						onClick={() =>
							meetingsToAddToCollection.length
								? bulkAddMeetingsToCollection()
								: handleAdd()
						}
						variant='subtle'
						radius='md'
						px={'sm'}
						loading={buttonLoading}
					>
						Add
					</Button>
				</Group>
				{myCollections.length ? (
					<Stack spacing={'xs'}>
						<Text>Collections already belonged to</Text>
						<List
							size={'sm'}
							styles={{
								itemWrapper: {
									width: '100%',
								},
							}}
						>
							{myCollections.map((collection) => {
								return (
									<List.Item key={collection.id}>
										<Group position='apart' noWrap mr={17}>
											<Text>{collection.name}</Text>
											<CloseButton
												aria-label='Remove meeting from collection'
												onClick={() =>
													handleRemove(collection, meetingToAddToCollection.id)
												}
											/>
										</Group>
									</List.Item>
								);
							})}
						</List>
					</Stack>
				) : null}
				{collectionsSharedWithMe.length ? (
					<Stack spacing={'xs'}>
						<Text>Collections Shared With Me</Text>
						<List size={'sm'}>
							{collectionsSharedWithMe.map((collection) => {
								return (
									<List.Item key={collection.id}>{collection.name}</List.Item>
								);
							})}
						</List>
					</Stack>
				) : null}
			</Stack>
		</Modal>
	);
}
