import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const transferMeetingsOwner = (meetingIDs: string[], userID: string) => {
	return axiosInstance
		.post(`v1/meetings/transfer-owner`, {
			meetingIDs,
			userID,
		})
		.then((res) => {
			logger('info', 'Response received from getMeetingNotifications', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error with transferMeetingsOwner', err);
			return err;
		});
};

export const generateMeetingTranscript = (meetingID: string) => {
	return axiosInstance
		.post(`v1/meeting/${meetingID}/generate-transcript`)
		.then((res) => {
			logger('info', 'Response received from getMeetingNotifications', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error with generateMeetingTranscript', err);
			return err;
		});
};
