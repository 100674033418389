import {Button, PinInput, Stack, Text, createStyles, ActionIcon, Group} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft} from "@fortawesome/pro-light-svg-icons";
import React from "react";

interface Props {
	authForm: UseFormReturnType<{ authCode: string }>;
	form: any;
	resendButtonLoading: boolean;
	verifyButtonLoading: boolean;
	handleAuthCode: (values: { authCode: string }) => void;
	handleSendAuthCode: (email: string) => void;
	handleNavigateBack: () => void;
}

const useStyles = createStyles((theme) => ({
	control: {
		[theme.fn.smallerThan('xs')]: {
			width: '100%',
			textAlign: 'center',
		},
		width: '352px',
		height: '42px',
		fontSize: '16px',
	},
}));

export default function OTPInput({
	form,
	authForm,
	resendButtonLoading,
	verifyButtonLoading,
	handleAuthCode,
	handleSendAuthCode,
	handleNavigateBack,
}: Props) {
	const { classes } = useStyles();
	return (
		<form onSubmit={authForm.onSubmit((values) => handleAuthCode(values))}>
			<Stack align='center'>
				<Text color='secondary-text' w={'70%'} align='center'>
					Enter the one-time password you received in your email below.
				</Text>
				<Group>
					<ActionIcon
						radius='xl'
						onClick={handleNavigateBack}
					>
						<FontAwesomeIcon
							size='xl'
							icon={faCircleArrowLeft}
						/>
					</ActionIcon>
					<PinInput
						length={6}
						type={'number'}
						aria-label='One time code'
						oneTimeCode
						required
						{...authForm.getInputProps('authCode')}
						size='lg'
						styles={{
							root: {
								display: 'flex',
								justifyContent: 'center',
							},
						}}
					/>
				</Group>
				<Button
					type='submit'
					className={classes.control}
					loading={verifyButtonLoading}
					radius={'md'}
				>
					Verify
				</Button>
				<Button
					className={classes.control}
					loading={resendButtonLoading}
					radius={'md'}
					variant='outline'
					onClick={() => {
						handleSendAuthCode(form.values.email);
					}}
				>
					Resend code
				</Button>
			</Stack>
		</form>
	);
}
