import React, { useEffect, useState } from 'react';
import { Grid, ScrollArea, Stack, Title } from '@mantine/core';
import IntegrationCard from '../../components/integration_card';
import {
	calendarIntegrationList,
	communicationsIntegrationList,
	flagsmithFeatureFlags,
} from '../../components/constants';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser } from '../../Atoms/userAtoms';
import { integrations as integrationsAtom } from '../../Atoms/integrations';
import { getUserCalendars } from '../../api/api';
import { logger } from '../../helpers/logger';
import ReelayLogoPageLoader from '../../components/loaders/ReelayLogoPageLoader';
import { UserRoles } from '../../interfaces/user';
import { useFlags } from 'flagsmith/react';
import { getEnvForGleap } from '../../_utils/trackers';

const calendars = [];
let communications = [];
calendarIntegrationList.forEach((item, index) => {
	calendars.push(
		<IntegrationCard
			key={item.platform}
			title={calendarIntegrationList[index]['Title']}
			image={calendarIntegrationList[index]['image']}
			enabled={calendarIntegrationList[index]['enabled']}
			description={calendarIntegrationList[index]['description']}
			data={calendarIntegrationList[index]}
			type={item.type}
		/>
	);
});

communicationsIntegrationList.forEach((item, index) => {
	communications.push(
		<IntegrationCard
			key={item.platform}
			title={communicationsIntegrationList[index]['Title']}
			image={communicationsIntegrationList[index]['image']}
			enabled={communicationsIntegrationList[index]['enabled']}
			description={communicationsIntegrationList[index]['description']}
			data={communicationsIntegrationList[index]}
			type={item.type}
		/>
	);
});

export default function Integrations() {
	const { zoomIntegrationCard } = flagsmithFeatureFlags;
	const flags = useFlags([zoomIntegrationCard]);
	const zoomCardEnabled = flags?.zoom_integration_card?.enabled;

	const user = useRecoilValue(currentUser);
	const setIntegrations = useSetRecoilState(integrationsAtom);
	const [loading, setLoading] = useState(true);

	communications = communications.filter((i) => {
		if (i.key === 'zoom' && !zoomCardEnabled) return;
		return i;
	});

	const filteredCommunications = communications.filter((item) => {
		return (
			item.platform !== 'slack' ||
			user.roles.some((role) =>
				[UserRoles.Admin, UserRoles.MasterAdmin, UserRoles.SuperAdmin].includes(
					role
				)
			)
		);
	});

	/**
	 * fetch existing calendar integrations (if any)
	 */
	useEffect(() => {
		const fetchIntegrations = async () => {
			setLoading(true);
			const res = await getUserCalendars(user.currentOrganizationID);
			setIntegrations(res.data.data);
			return res.data.data;
		};

		fetchIntegrations()
			.then((res) => {
				logger('info', 'fetchIntegrations res', res);
			})
			.catch((err) => {
				logger('error', 'fetchIntegrations err', err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			{loading ? (
				<ReelayLogoPageLoader />
			) : (
				<Stack h={'100%'} pt='sm'>
					<ScrollArea>
						<Title
							order={4}
							id={`VIS_ctf9nfdtmsxfgg2vmch0_${getEnvForGleap()}`}
						>
							Calendars
						</Title>
						<Grid align={'center'}>{calendars}</Grid>
						<Title
							order={4}
							id={`VIS_ctf9nfdtmsxfgg2vmchg_${getEnvForGleap()}`}
						>
							Communications
						</Title>
						<Grid align={'center'}>{filteredCommunications}</Grid>
					</ScrollArea>
				</Stack>
			)}
		</>
	);
}
