import React, { useEffect, useState } from 'react';
import {
	UnstyledButton,
	Group,
	Text,
	createStyles,
	Collapse,
	Stack,
} from '@mantine/core';
import { NavLink as Link, useLocation } from 'react-router-dom';
import {
	adminNavLinks,
	flagsmithFeatureFlags,
	navigationLinks,
} from '../constants';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeLink, hasAdminAccess } from '../../Atoms/userAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { navbarOpenedAtom } from '../../Atoms/layout';
import { useFlags } from 'flagsmith/react';
import { getEnvForGleap } from '../../_utils/trackers';

interface MainLinkProps {
	icon: React.ReactNode;
	label: string;
	path: string;
	nestedLinks?: { label: string; path: string }[];
}

const useStyles = createStyles((theme) => ({
	control: {
		fontWeight: 400,
		display: 'block',
		width: '100%',
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
		fontSize: theme.fontSizes.sm,
		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[7]
					: theme.colors.gray[0],
			color: theme.colorScheme === 'dark' ? theme.white : theme.black,
		},
	},

	link: {
		fontWeight: 400,
		display: 'block',
		width: '100%',
		marginLeft: 32,
		padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
		paddingLeft: '10%',
		borderTopRightRadius: theme.radius.md,
		borderBottomRightRadius: theme.radius.md,
		borderRadius: theme.radius.md,
		fontSize: 16,
		color: theme.colors['primary-text'],
		borderLeft: `1px solid ${theme.colors['primary'][0]}`,
		'&:hover': {
			backgroundColor: 'rgba(224,231,255,0.65)',
			color: theme.colors['reelay-secondary-dark'],
		},
		'&:active': {
			backgroundColor: theme.colors.primary[0],
			color: theme.colors['reelay-secondary-dark'],
		},
	},

	chevron: {
		transition: 'transform 200ms ease',
	},
}));

function MainLink({ icon, label, path, nestedLinks }: MainLinkProps) {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const [active, setActive] = useRecoilState(activeLink);
	const setNavbarOpened = useSetRecoilState(navbarOpenedAtom);
	const [nestedActive, setNestedActive] = useState('');
	const hasLinks = Boolean(nestedLinks);
	const location = useLocation();

	const firstPrimaryColorIndexThatIsNotWhite = theme.colors.primary.findIndex(
		(color) => color !== '#ffffff'
	);

	useEffect(() => {
		if (location.pathname.includes('/meetings')) {
			setActive('Library');
		}
		if (location.pathname.includes('/recordings')) {
			setNestedActive('Recordings');
		} else if (location.pathname.includes('/minutes')) {
			setNestedActive('Minutes');
		} else if (location.pathname.includes('/schedule')) {
			setNestedActive('Schedule');
		} else {
			setNestedActive('');
		}
	}, [location.pathname]);

	const items = (hasLinks ? nestedLinks : []).map((link) => (
		<Group key={link.label} position={'right'} spacing={0} id={`VIS_ctf9nfdtmsxfgg2vmcj0_${getEnvForGleap()}`}>
			<Text<'a'>
				component='a'
				className={classes.link}
				href={link.path}
				key={link.label}
				onClick={(event) => {
					event.preventDefault();
					setNestedActive(link.label);
					navigate(link.path);
					setNavbarOpened(false);
				}}
				style={
					nestedActive === link.label
						? {
								color: 'primary',
								backgroundColor:
									theme.colors.primary[firstPrimaryColorIndexThatIsNotWhite],
						  }
						: {}
				}
				p={'xs'}
				id={`VIS_ctf9nfdtmsxfgg2vmckg_${getEnvForGleap()}`}
			>
				{link.label}
			</Text>
		</Group>
	));

	return (
		<>
			<UnstyledButton
				sx={(theme) => ({
					display: 'block',
					width: '100%',
					padding: theme.spacing.sm,
					borderRadius: theme.radius.md,
					marginBottom: '10px',
					color: theme.colors['primary-text'],
					'&:hover': {
						backgroundColor: theme.colors.primary[0],
						color: theme.colors['reelay-secondary-dark'],
					},
					'&:active': {
						backgroundColor: theme.colors.primary[0],
						color: theme.colors['reelay-secondary-dark'],
					},
				})}
				component={Link}
				to={path}
				onClick={() => {
					setActive(label);
					setNavbarOpened(false);
				}}
				style={
					active === label
						? {
								color: 'primary',
								backgroundColor:
									theme.colors.primary[firstPrimaryColorIndexThatIsNotWhite],
						  }
						: {}
				}
			>
				<Group position={'apart'} spacing={0}>
					<Group pl={'sm'} noWrap>
						{icon}
						<Text size='lg' weight={400}>
							{label}
						</Text>
					</Group>
				</Group>
			</UnstyledButton>
			{hasLinks ? (
				<Collapse in={true} mb={'0.625rem'}>
					<Stack spacing={'xs'}>{items}</Stack>
				</Collapse>
			) : null}
		</>
	);
}

export default function MainLinks() {
	const adminAccess = useRecoilValue(hasAdminAccess);
	const { meetingCollections, actionItems } = flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections, actionItems]);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const actionItemsEnabled = flags.action_items.enabled;

	if (adminAccess) {
		const links = adminNavLinks.map((link) => {
			const { icon, label, path } = link;
			const iconItem = icon ? <FontAwesomeIcon icon={icon} /> : null;
			return (
				<MainLink icon={iconItem} path={path} label={label} key={link.label} />
			);
		});
		return <div>{links}</div>;
	} else {
		const navLinks = navigationLinks.filter((link) =>
			link.label === 'Actions'
				? actionItemsEnabled
				: link.label === 'Collections'
				? collectionsEnabled
				: true
		);
		const links = navLinks.map((link) => {
			const { icon, label, path } = link;
			const iconItem = icon ? <FontAwesomeIcon icon={icon} /> : null;
			return (
				<MainLink
					icon={iconItem}
					path={path}
					label={label}
					key={link.label}
					nestedLinks={link.nestedLinks}
				/>
			);
		});
		return <div>{links}</div>;
	}
}
