import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	Table,
	Paper,
	Group,
	ActionIcon,
	Badge,
	Menu,
	Stack,
	ScrollArea,
	Switch,
} from '@mantine/core';
import { DateTime } from 'luxon';
import { getUsersByOrg, getUserStats, saveUserSettings } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrash,
	faUserPen,
	faUserSlash,
	faEllipsisVertical,
	faArrowUpFromLine,
	faCalendar,
	faAt,
} from '@fortawesome/pro-solid-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentUser,
	User,
	userOrganizationMembers,
	UserStats,
} from '../../Atoms/userAtoms';
import { logger } from '../../helpers/logger';
import EditUserModal from './EditUserModal';
import TransferMeetingsModal from './TransferMeetingsModal';
import { Organization } from '../../Atoms/organizationAtom';
import ReelayLogoPageLoader from '../loaders/ReelayLogoPageLoader';
import { organizationAtom as organizationAtom } from '../../Atoms/organizationAtom';
import { useDisclosure } from '@mantine/hooks';
import DeleteUserModal from './DeleteUserModal';
import { UserRoles } from '../../interfaces/user';
import SearchBar from '../SearchBars/SearchBar';
import CreateQuickAddUserModal from '../create_quickadd_user_modal';
import { getEnvForGleap } from '../../_utils/trackers';
import { resyncCalendars } from '../../api/recall';
import { OrganizationSettings } from '../../Atoms/settings';
import ManageAliasesModal from './ManageAliasesModal';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { isSupportUser, isUserAdmin } from '../../helpers/auth';

export const userDefaultValue = {
	firstName: '',
	lastName: '',
	email: '',
	roles: [],
};

export default function OrganizationMembersTable({
	organizationID,
	auditSide = false,
	organizationSettings,
	showSkipAudit,
}: {
	organizationID: string;
	auditSide?: boolean;
	organizationSettings?: OrganizationSettings;
	showSkipAudit: boolean;
}) {
	const [members, setMembers] = useRecoilState(userOrganizationMembers);
	const [filteredMembers, setFilteredMembers] = useState(members);
	const [loading, setLoading] = useState(false);
	const organization = useRecoilValue(organizationAtom);
	const user = useRecoilValue(currentUser);
	const [isEditUserModalOpened, setEditUserModalOpened] = useState(false);
	const [isTransferringModalOpened, setTransferMeetingsModalOpened] =
		useState(false);
	const [deletingUser, setDeletingUser] = useState<User>(userDefaultValue);
	const [isDeleting, setIsDeleting] = useState(false);
	const [statsByOrganizationID, setStatsByOrganizationID] = useState<{
		[orgID: string]: UserStats;
	}>({});
	const [removingFromAllOrganizations, setRemovingFromAllOrganizations] =
		useState<boolean>(false);
	const [
		isDeleteUserModalOpened,
		{ open: openDeleteUserModal, close: closeDeleteUserModal },
	] = useDisclosure(false);
	const [
		isDeleteUserFromOrganizationModalOpened,
		{
			open: openDeleteUserFromOrganizationModal,
			close: closeDeleteUserFromOrganizationModal,
		},
	] = useDisclosure(false);
	const [editingMember, setEditingMember] = useState<User>(userDefaultValue);
	const [transferMeetingsModalMember, setTransferMeetingsModalMember] =
		useState<User>(userDefaultValue);
	const { Admin, SuperAdmin, Support, MasterAdmin } = UserRoles;
	const allowedToEditUsers = user.roles.some((role: UserRoles) =>
		[Admin, Support, SuperAdmin, MasterAdmin].includes(role)
	);
	const isAdminOrSupport = isSupportUser(user.roles) || isUserAdmin(user.roles);
	const headerRef = useRef(null);
	const membersHashMap = useMemo(() => {
		const map = new Map();
		members.forEach((member) => {
			map.set(member.id, member);
		});
		return map;
	}, [members]);

	// filter search bar
	const [searchValue, setSearchValue] = useState('');

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		setSearchValue(value);
		// filter data based off team name and user's firstName, lastName, email.
		const filteredMembersArray = members.filter((member) => {
			const lowercaseValue = value.toLowerCase();
			return (
				(member.firstName || '').toLowerCase().includes(lowercaseValue) ||
				(member.lastName || '').toLowerCase().includes(lowercaseValue) ||
				(member.email || '').toLowerCase().includes(lowercaseValue) ||
				member.roles.some((role) => role.toLowerCase().includes(lowercaseValue))
			);
		});
		setFilteredMembers(filteredMembersArray);
	};

	useEffect(() => {
		// only fire this if it's on the audit side
		if (auditSide) getUsersForOrg();
	}, [organizationID]);

	useEffect(() => {
		setFilteredMembers(members);
	}, [members]);

	useEffect(() => {
		// only fire this if it's on the audit side
		if (auditSide) getUsersForOrg();
	}, [organizationID]);

	const getUsersForOrg = async () => {
		try {
			setLoading(true);
			const members = await getUsersByOrg(organizationID, true);
			logger('info', 'Members for organization', members);
			setMembers(members);
			setFilteredMembers(members);
		} catch (error) {
			logger('error', 'Failed to get members for organization', error);
		} finally {
			setSearchValue('');
			setLoading(false);
		}
	};

	const editUser = (member: User) => {
		setEditingMember(member);
		setEditUserModalOpened(true);
	};

	const transferMeetings = (member: User, isDeleting) => {
		setIsDeleting(isDeleting);
		setTransferMeetingsModalMember(member);
		setTransferMeetingsModalOpened(true);
	};

	const openRemoveUserFromOrganizationModal = async (
		member: User,
		removingFromAllOrganizations: boolean
	) => {
		const stats = await getUserStats(member.id);

		setIsDeleting(true);
		setRemovingFromAllOrganizations(removingFromAllOrganizations);
		setStatsByOrganizationID(stats);
		setDeletingUser(member);
		openDeleteUserModal();
	};

	const handleResyncCalendars = async (member: User) => {
		try {
			const res = await resyncCalendars(member.id, organizationID);
			logger('info', 'Resynced user calendar', res);
			showSuccessNotification({
				message: 'User calendar successfully resynced.',
			});
		} catch (error) {
			logger('error', 'Failed to resync user calendar', error);
			showFailureNotification({
				message: error?.response?.data || 'Failed to resync user calendar.',
			});
		}
	};

	const handleSkipAuditChange = async (member: User, value: boolean) => {
		try {
			await saveUserSettings(member.id, [
				{
					key: 'skip_audit',
					value,
				},
			]);
			setMembers((prevMembers) =>
				prevMembers.map((m) =>
					m.id === member.id ? { ...m, skipAudit: value } : m
				)
			);
			const message = value
				? `User ${member.firstName} ${member.lastName}'s meetings will now skip audit.`
				: `User ${member.firstName} ${member.lastName}'s meetings will no longer skip audit.`;
			showSuccessNotification({
				message,
			});
		} catch (error) {
			logger('error', 'Failed to update user skip audit setting', error);
			showFailureNotification({
				message: 'Failed to update user skip audit setting, please try again.',
			});
		}
	};

	const [manageAliasesModalOpened, setManageAliasesModalOpened] =
		useState(false);
	const [selectedMember, setSelectedMember] = useState<User | null>(null);

	const manageAliases = (member: User) => {
		setSelectedMember(member);
		setManageAliasesModalOpened(true);
	};

	const ths = (
		<tr>
			<th>Name</th>
			<th>Email</th>
			<th>Roles</th>
			<th>Created At</th>
			{showSkipAudit && <th>Skip Audit</th>}
			<th></th>
		</tr>
	);

	const rows = (filteredMembers || []).map((member: User) => {
		const skipAudit =
			typeof member.skipAudit === 'boolean' // use the user's setting if its a true boolean
				? member.skipAudit
				: organizationSettings?.skip_audit; // else use the org's setting
		return (
			<tr key={member.id} id={`VIS_ctf9nfdtmsxfgg2vmcmg_${getEnvForGleap()}`}>
				<td>
					{member.firstName || ''} {member.lastName || ''}
				</td>
				<td>{member.email}</td>
				<td>
					{(member?.roles || []).map((role: string) => {
						return roleBadge(role);
					})}
				</td>
				<td>
					{DateTime.fromISO(member.createdAt).toLocaleString(DateTime.DATE_MED)}
				</td>
				{showSkipAudit && (
					<td>
						<Switch
							checked={skipAudit}
							onChange={(event) =>
								handleSkipAuditChange(member, event.currentTarget.checked)
							}
						/>
					</td>
				)}
				<td id={`VIS_ctf9nfdtmsxfgg2vmcn0_${getEnvForGleap()}`}>
					<Group>
						{allowedToEditUsers ? (
							<Menu
								shadow='md'
								width={200}
								position='right-start'
								withArrow
								arrowPosition='center'
								withinPortal={true}
							>
								<Menu.Target>
									<ActionIcon>
										<FontAwesomeIcon icon={faEllipsisVertical} />
									</ActionIcon>
								</Menu.Target>

								<Menu.Dropdown>
									<Menu.Label>Application</Menu.Label>
									<Menu.Item
										onClick={() => editUser(member)}
										icon={<FontAwesomeIcon icon={faUserPen} size={'xs'} />}
									>
										Edit User
									</Menu.Item>
									<Menu.Item
										onClick={() => handleResyncCalendars(member)}
										icon={<FontAwesomeIcon icon={faCalendar} size={'xs'} />}
									>
										Resync calendars
									</Menu.Item>

									<Menu.Item
										onClick={() => manageAliases(member)}
										icon={<FontAwesomeIcon icon={faAt} size={'xs'} />}
									>
										Manage Aliases
									</Menu.Item>
									<Menu.Label>Meetings</Menu.Label>
									<Menu.Item
										onClick={() => transferMeetings(member, false)}
										icon={
											<FontAwesomeIcon icon={faArrowUpFromLine} size={'xs'} />
										}
									>
										Transfer
									</Menu.Item>

									<Menu.Label>Danger zone</Menu.Label>
									{isAdminOrSupport && (
										<Menu.Item
											onClick={() =>
												openRemoveUserFromOrganizationModal(member, false)
											}
											icon={<FontAwesomeIcon icon={faUserSlash} size={'xs'} />}
										>
											Remove from Org
										</Menu.Item>
									)}

									{user.roles.some((role) => ['support'].includes(role)) && (
										<Menu.Item
											onClick={() =>
												openRemoveUserFromOrganizationModal(member, true)
											}
											color='red'
											icon={<FontAwesomeIcon icon={faTrash} size={'xs'} />}
										>
											Delete User
										</Menu.Item>
									)}
								</Menu.Dropdown>
							</Menu>
						) : null}
					</Group>
				</td>
			</tr>
		);
	});

	return (
		<Stack h={'100%'} spacing={0}>
			<Group
				position='right'
				mb='xl'
				style={{ boxSizing: 'border-box' }}
				ref={headerRef}
				pt='sm'
			>
				<Group noWrap id={`VIS_ctf9nfdtmsxfgg2vmcng_${getEnvForGleap()}`}>
					<SearchBar
						searchValue={searchValue}
						placeholder={'Search members'}
						handleSearchChange={handleSearchChange}
					/>
					<CreateQuickAddUserModal orgID={organizationID} />
				</Group>
			</Group>
			<div
				style={{
					flex: 2,
					height: `calc(100% - ${
						headerRef?.current?.offsetHeight || 0
					}px - 24px)`,
					overflowY: 'hidden',
				}}
			>
				{loading ? (
					<ReelayLogoPageLoader />
				) : (
					<div
						style={{
							flex: 2,
							height: `100%`,
							overflowY: 'hidden',
						}}
					>
						<ManageAliasesModal
							opened={manageAliasesModalOpened}
							onClose={() => setManageAliasesModalOpened(false)}
							userAliases={selectedMember?.emailAliases || []}
							user={selectedMember}
							organizationID={organizationID}
						/>
						<Paper radius='md' shadow='md' withBorder h={'100%'}>
							<ScrollArea h={'100%'}>
								<Table striped highlightOnHover>
									<thead>{ths}</thead>
									<tbody>{rows}</tbody>
								</Table>
							</ScrollArea>
						</Paper>
						<TransferMeetingsModal
							fromMember={transferMeetingsModalMember}
							members={members}
							opened={isTransferringModalOpened}
							setOpened={setTransferMeetingsModalOpened}
							setFromMember={setTransferMeetingsModalMember}
							isDeleting={isDeleting}
							removingFromAllOrganizations={removingFromAllOrganizations}
							openRemoveUserFromOrganizationModal={
								openRemoveUserFromOrganizationModal
							}
							membersHashMap={membersHashMap}
						/>
						<EditUserModal
							member={editingMember}
							opened={isEditUserModalOpened}
							setOpened={setEditUserModalOpened}
						/>
						<DeleteUserModal
							member={deletingUser}
							removingFromAllOrganizations={removingFromAllOrganizations}
							statsByOrganizationID={statsByOrganizationID}
							opened={isDeleteUserModalOpened}
							close={closeDeleteUserModal}
							transfer={() => transferMeetings(deletingUser, true)}
						/>
					</div>
				)}
			</div>
		</Stack>
	);
}

const roleBadge = (role: string) => {
	switch (role) {
		case 'member':
			return (
				<Badge key={role} size='xs' color={'gray'}>
					Member
				</Badge>
			);
		case 'admin':
			return (
				<Badge key={role} size='xs' color={'blue'}>
					Admin
				</Badge>
			);
		case 'superadmin':
			return (
				<Badge key={role} size='xs' color={'grape'}>
					Super Admin
				</Badge>
			);
		case 'create-meeting':
			return (
				<Badge key={role} size='xs' color={'green'}>
					Create Meeting
				</Badge>
			);
		case 'invite-to-organization':
			return (
				<Badge key={role} size='xs' color={'teal'}>
					Invite to Organization
				</Badge>
			);
		case 'support':
			return (
				<Badge key={role} size='xs' color={'violet'}>
					Support
				</Badge>
			);
		case 'guest':
			return (
				<Badge key={role} size='xs' color={'orange'}>
					Guest
				</Badge>
			);
		case 'project-manager':
			return (
				<Badge key={role} size='xs' color='indigo'>
					Project Manager
				</Badge>
			);
	}
};
