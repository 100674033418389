/* eslint-disable no-unreachable */
import React, { useState } from 'react';
import {
	Modal,
	Button,
	TextInput,
	MultiSelect,
	Text,
	HoverCard,
	Group,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { inviteUserToOrganization, registerUser } from '../../api/api';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
	roles,
} from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser, userOrganizationMembers } from '../../Atoms/userAtoms';
import { UsersMultiSelect } from '../UsersMultiSelect';
import { logger } from '../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';

export default function CreateQuickAddUserModal(orgID: any) {
	const user = useRecoilValue(currentUser);
	const [users, setUsers] = useRecoilState(userOrganizationMembers);

	const [opened, setOpened] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();

	const allowedToAddUser = user.roles.some((role: string) =>
		['superadmin', 'admin', 'support', 'invite-to-organization'].includes(role)
	);

	const form = useForm({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			roles: [],
		},
		validate: {
			firstName: (value) => (value.length ? null : 'Invalid first name'),
			lastName: (value) => (value.length ? null : 'Invalid last name'),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			roles: (value) => (value.length ? null : 'A role is required.'),
		},
	});

	async function inviteUser(data: any) {
		try {
			setLoading(true);

			// Register the user
			try {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const res = await registerUser(data);
				logger('info', 'User was successfully registered', res);
			} catch (err: any) {
				logger('error', 'Error registering user', err);
				throw err; // Re-throw the error to be caught by the outer catch block
			}

			// Invite the user to the organization
			logger('info', 'Inviting user to organization', data);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const response = await inviteUserToOrganization(data, orgID.orgID);
			logger('info', 'Invited user to organization', response);
			const newUser = response.data.data.user;
			setUsers((prevUsers) => [...prevUsers, newUser]);

			showSuccessNotification({
				message: 'User was successfully invited! Great work!',
			});
		} catch (err: unknown) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setError(err.message);
			logger('error', 'Error inviting user to organization', err);

			showFailureNotification({
				message: 'Sorry, failed to invite user. Please try again.',
			});
		} finally {
			setLoading(false);
			setOpened(false);
			form.reset();
		}
	}

	return (
		<>
			<Modal
				opened={opened}
				centered={true}
				onClose={() => setOpened(false)}
				title='Invite User to Organization'
			>
				<form onSubmit={form.onSubmit((values) => inviteUser(values))}>
					<TextInput
						mb='sm'
						label={'First Name'}
						{...form.getInputProps('firstName')}
					/>
					<TextInput
						mb='sm'
						label={'Last Name'}
						{...form.getInputProps('lastName')}
					/>
					<TextInput mb='sm' label={'Email'} {...form.getInputProps('email')} />
					<UsersMultiSelect
						errorText='A role is required.'
						data={roles}
						labelText={'Roles'}
						placeholder='Select Roles'
						name={'roles'}
						form={form}
					/>
					<Group mt={'md'} position={'right'}>
						<Button radius={'md'} type='submit' loading={loading}>
							Invite
						</Button>
					</Group>
				</form>
			</Modal>

			{allowedToAddUser ? (
				<Button
					radius={'md'}
					leftIcon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() => setOpened(true)}
				>
					Add User
				</Button>
			) : null}
		</>
	);
}
