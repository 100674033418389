import { Stack, Title, ScrollArea } from '@mantine/core';
import MeetingRecordingItem from './MeetingRecordingItem';
import { useRecoilValue } from 'recoil';
import { liveMeetingsWithBots } from '../../Atoms/recallAI';

export default function MeetingRecordingStack() {
	const liveMeetings = useRecoilValue(liveMeetingsWithBots);

	if (!liveMeetings.length) return null;

	return (
		<Stack
			justify='flex-end'
			style={{
				height: 150,
				flexShrink: 0,
				width: '100%',
				overflowY: 'hidden',
			}}
		>
			{liveMeetings.length ? (
				<Title order={6} weight={500}>
					Happening Now
				</Title>
			) : null}
			<ScrollArea offsetScrollbars scrollbarSize={10} style={{ flex: 1 }}>
				<Stack spacing={'sm'} style={{ flex: 1 }}>
					{liveMeetings.map((meeting) => (
						<MeetingRecordingItem key={meeting.id} meeting={meeting} />
					))}
				</Stack>
			</ScrollArea>
		</Stack>
	);
}
