import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Register from '../components/Register';
import OrganizationCreation from '../components/OrganizationCreation';
import InviteTeam from '../components/InviteTeam';
import InfoCollection from '../components/InfoCollection';
import SetPreferences from '../components/SetPreferences';
import ConnectCalendar from '../components/ConnectCalendar';
import {
	RegisterData,
	OrganizationData,
	InviteTeamData,
	InfoCollectionData,
	PreferencesData,
} from '../types';
import OnboardingLayout from '../OnboardingLayout';
import {
	getCurrentOrganization,
	getCurrentUser,
	getUsersByOrg,
} from '../../../api/api';
import { useSetRecoilState } from 'recoil';
import {
	user,
	userOrganization,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { organizationBrand } from '../../../Atoms/settings';
import { logger } from '../../../helpers/logger';
import { getOrganizationBrand } from '../../../api/settings';

const SESSION_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

const NewUsersWithoutOrg: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const step = searchParams.get('step') || '1';
	console.log('step', step);

	// State for each step
	const [registerData, setRegisterData] = useState<RegisterData>(() =>
		JSON.parse(localStorage.getItem('registerData') || '{}')
	);
	const [organizationData, setOrganizationData] = useState<OrganizationData>(
		() => JSON.parse(localStorage.getItem('organizationData') || '{}')
	);
	const [inviteTeamData, setInviteTeamData] = useState<InviteTeamData>(() =>
		JSON.parse(localStorage.getItem('inviteTeamData') || '{}')
	);
	const [infoCollectionData, setInfoCollectionData] =
		useState<InfoCollectionData>(() =>
			JSON.parse(localStorage.getItem('infoCollectionData') || '{}')
		);
	const [preferencesData, setPreferencesData] = useState<PreferencesData>(() =>
		JSON.parse(localStorage.getItem('preferencesData') || '{}')
	);
	const [organizationID, setOrganizationID] = useState<string | null>(null);
	const [authTokenIsSet, setAuthTokenIsSet] = useState(false);
	const setUser = useSetRecoilState(user);
	const setOrganizationMembers = useSetRecoilState(userOrganizationMembers);
	const setBrand = useSetRecoilState(organizationBrand);
	const setOrganization = useSetRecoilState(userOrganization);

	// Handle next step
	const handleNext = () => {
		const nextStep = Number(step) + 1;
		setSearchParams({ step: nextStep.toString() });
	};

	const saveDataToLocalStorage = (key: string, data: any) => {
		localStorage.setItem(key, JSON.stringify(data));
	};

	// Keys in local storage
	const localStorageKeys = [
		'sessionStart',
		'registerData',
		'organizationData',
		'inviteTeamData',
		'infoCollectionData',
		'preferencesData',
		'calendarData',
	];

	// Check if session has expired and refresh session start time
	useEffect(() => {
		const sessionStart = localStorage.getItem('sessionStart');

		if (step === '1') {
			// Clear everything in local storage if on the first step
			localStorage.clear();
			setRegisterData({
				firstName: '',
				lastName: '',
				email: '',
				contactId: '',
			});
		} else if (
			sessionStart &&
			Date.now() - parseInt(sessionStart, 10) > SESSION_EXPIRY_TIME
		) {
			// Clear only the specific keys in local storage
			localStorageKeys.forEach((key) => localStorage.removeItem(key));
		}

		// Always set a new session start time
		localStorage.setItem('sessionStart', Date.now().toString());
	}, [step]);

	const handleGetCurrentUser = async (organizationID = '') => {
		try {
			const user = await getCurrentUser(organizationID);

			setUser(user);

			const { currentOrganizationID } = user;
			const usersResponse = await getUsersByOrg(currentOrganizationID);
			const users = usersResponse;
			setOrganizationMembers(users);

			return user;
		} catch (err) {
			logger('error', 'Error getting current user', err);
		}
	};

	const handleGetCurrentOrganization = async (organizationID: string) => {
		try {
			const currentOrg = await getCurrentOrganization(organizationID);
			console.log('currentOrg', currentOrg);
			setOrganization(currentOrg.data.data);

			// set brand if it exists
			const orgID = currentOrg.data.data.id;
			const brandData = await getOrganizationBrand(orgID);
			if (brandData?.value?.color_primary) {
				setBrand(brandData.value);
				localStorage.setItem('brand', JSON.stringify(brandData.value));
			}

			return currentOrg.data.data;
		} catch (err) {
			logger('error', 'Error getting current organization', err);
		}
	};

	const handleAfterAuthSetup = (organizationID: string) => {
		setTimeout(async () => {
			await Promise.all([
				handleGetCurrentUser(organizationID),
				handleGetCurrentOrganization(organizationID),
			]);
		}, 500);
	};

	useEffect(() => {
		console.log('authTokenIsSet', authTokenIsSet);
		console.log('organizationID', organizationID);
		if (authTokenIsSet && organizationID) {
			handleAfterAuthSetup(organizationID);
		}
	}, [authTokenIsSet, organizationID]);

	// Render component based on step
	const renderStepComponent = () => {
		switch (step) {
			case '1':
				return (
					<Register
						onNext={handleNext}
						data={registerData}
						setData={(data: RegisterData) => {
							setRegisterData(data);
							saveDataToLocalStorage('registerData', data);
						}}
						subTitle='First, enter your details:'
						buttonText='Next, let’s make a workspace'
					/>
				);
			case '2':
				return (
					<OrganizationCreation
						onNext={handleNext}
						data={organizationData}
						setData={(data: OrganizationData) => {
							setOrganizationData(data);
							saveDataToLocalStorage('organizationData', data);
						}}
					/>
				);
			case '3':
				return (
					<InviteTeam
						onNext={handleNext}
						data={inviteTeamData}
						setData={(data: InviteTeamData) => {
							setInviteTeamData(data);
							saveDataToLocalStorage('inviteTeamData', data);
						}}
					/>
				);
			case '4':
				return (
					<InfoCollection
						onNext={handleNext}
						data={infoCollectionData}
						setData={(data: InfoCollectionData) => {
							setInfoCollectionData(data);
							saveDataToLocalStorage('infoCollectionData', data);
						}}
					/>
				);
			case '5':
				return (
					<SetPreferences
						onNext={handleNext}
						data={preferencesData}
						setData={(data: PreferencesData) => {
							setPreferencesData(data);
							saveDataToLocalStorage('preferencesData', data);
						}}
						organizationData={organizationData}
						registerData={registerData}
						infoCollectionData={infoCollectionData}
						inviteTeamData={inviteTeamData}
						organizationID={organizationID}
						setOrganizationID={setOrganizationID}
						setAuthTokenIsSet={setAuthTokenIsSet}
					/>
				);
			case '6':
				return (
					<ConnectCalendar
						organizationID={organizationID}
						preferencesData={preferencesData}
					/>
				);
			default:
				return (
					<Register
						onNext={handleNext}
						data={registerData}
						setData={(data: RegisterData) => {
							setRegisterData(data);
							saveDataToLocalStorage('registerData', data);
						}}
						subTitle='First, enter your details:'
						buttonText='Next, let’s make a workspace'
					/>
				);
		}
	};

	return <OnboardingLayout>{renderStepComponent()}</OnboardingLayout>;
};

export default NewUsersWithoutOrg;
