import React from 'react';
import { Container, createStyles } from '@mantine/core';
import CollectionTable from './CollectionTable';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: 'calc(100vh - 70px)',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
}));

interface Props {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CollectionsOverview({ setOpened }: Props) {
	const { classes } = useStyles();

	return (
		<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
			<CollectionTable setOpened={setOpened} />
		</Container>
	);
}
