import React, { useState } from 'react';
import {
	Button,
	Stack,
	Text,
	Select,
	Group,
	createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { PreferencesData, RegisterData } from '../types';
import {
	getCurrentOrganization,
	getCurrentUser,
	getUsersByOrg,
	signUpUser,
} from '../../../api/api';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useSetRecoilState } from 'recoil';
import {
	authTokenExpirationTime,
	userAuthenticated,
} from '../../../Atoms/auth';
import {
	user,
	userOrganization,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { logger } from '../../../helpers/logger';
import { getOrganizationBrand } from '../../../api/settings';
import { organizationBrand } from '../../../Atoms/settings';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
	input: {
		backgroundColor: '#F5F5F5',
		borderRadius: theme.radius.sm,
	},
	label: {
		fontSize: 16,
		fontWeight: 500,
		color: theme.fn.primaryColor(),
		marginBottom: theme.spacing.xs,
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			fontSize: 14,
		},
	},
	optionButton: {
		backgroundColor: '#F5F5F5',
		border: `1px solid ${theme.colors.gray[5]}`,
		padding: '10px 20px',
		borderRadius: theme.radius.sm,
		cursor: 'pointer',
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'center',
		color: theme.black,
		flex: 1,
		'&:hover': {
			backgroundColor: '#E0E0E0',
		},
		'&:focus': {
			backgroundColor: '#D9D9D9',
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: '#D9D9D9',
			boxShadow: 'none',
		},
	},
	selectedOptionButton: {
		backgroundColor: '#D9D9D9',
		color: theme.black,
		'&:focus': {
			boxShadow: 'none',
		},
	},
	title: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: theme.spacing.xs,
		textAlign: 'left',
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			fontSize: 24,
		},
	},
}));

interface SetPreferencesProps {
	onNext: () => void;
	data: PreferencesData;
	setData: (data: PreferencesData) => void;
	registerData: RegisterData;
	organizationID: string;
	setAuthTokenIsSet: (value: boolean) => void;
}

const UserInviteSetPreferences: React.FC<SetPreferencesProps> = ({
	onNext,
	data,
	setData,
	registerData,
	organizationID,
	setAuthTokenIsSet,
}) => {
	const { classes } = useStyles();
	const setAuthStatus = useSetRecoilState(userAuthenticated);
	const setAuthTokenExpirationTime = useSetRecoilState(authTokenExpirationTime);
	const [dailySchedule, setDailySchedule] = useState<boolean | null>(
		data.dailySchedule || null
	);
	const [loading, setLoading] = useState(false);
	const isMediumScreen = useMediaQuery('(max-width: 768px)');

	const form = useForm({
		initialValues: {
			autoRecord: data.autoRecord || '',
			postMeetingDistribution: data.postMeetingDistribution || '',
		},
		validate: {
			autoRecord: (value) =>
				value.trim().length === 0 ? 'Please select a meeting type' : null,
			postMeetingDistribution: (value) =>
				value.trim().length === 0
					? 'Please select a distribution option'
					: null,
		},
	});

	const handleSubmit = async (values: typeof form.values) => {
		try {
			if (dailySchedule === null) {
				alert('Please fill out all fields.');
				return;
			}
			setLoading(true);
			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			notifications.show({
				id: 'submit-data',
				loading: true,
				title: 'Submitting your data...',
				message: 'Please wait a moment while we create your organization.',
				autoClose: false,
				withCloseButton: false,
			});

			const newestData = { ...values, dailySchedule };
			setData(newestData);

			const organizationCreated = await signUpUser({
				registerData,
				organizationID,
				preferencesData: newestData,
				timezone,
			});

			const { expiresAt, user } = organizationCreated?.data?.data ?? {};
			setAuthStatus(true);
			setAuthTokenExpirationTime(expiresAt);
			setAuthTokenIsSet(true);

			// setUser((prev) => ({ ...prev, ...user }));
			notifications.update({
				id: 'submit-data',
				color: 'teal',
				title: 'Success!',
				message:
					'Your account has been created. Please complete the final step of connecting your calendar.',
				icon: <IconCheck size='1rem' />,
				autoClose: 5000,
			});
			onNext();
		} catch (error) {
			console.log('error', error);
			notifications.update({
				id: 'submit-data',
				color: 'red',
				title: 'Error',
				message:
					error?.response?.data || 'An error occurred. Please try again later.',
				icon: <IconX size='1rem' />,
				autoClose: 2000,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Stack spacing={0} px='xl'>
			<Text className={classes.title}>Let’s set your meeting preferences.</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Select
					label='What meetings should Reelay attend?'
					placeholder='Select meeting type'
					data={[
						{
							value: '1',
							label: "My Meetings (only the virtual meetings I've scheduled)",
						},
						{
							value: '3',
							label:
								"My Meetings + Team Meetings (other company virtual meetings I've been invited to)",
						},
						{
							value: '7',
							label: 'All Meetings (every virtual meeting on my calendar)',
						},
					]}
					required
					{...form.getInputProps('autoRecord')}
					classNames={{ input: classes.input, label: classes.label }}
				/>

				<Select
					label='Who should receive post-meeting recordings, recaps, and notes by default?'
					placeholder='Select recipients'
					data={[
						{
							label: 'Calendar invitees who are on my team',
							value: 'invitees',
						},
						{ label: 'All meeting participants', value: 'attendees' },
						{ label: 'All calendar invitees', value: 'all' },
						{ label: 'None', value: 'none' },
					]}
					required
					{...form.getInputProps('postMeetingDistribution')}
					classNames={{ input: classes.input, label: classes.label }}
					mt='md'
				/>

				{isMediumScreen ? (
					<Stack spacing='lg' mt='lg'>
						<div>
							<Text className={classes.label}>
								Want to see tomorrow's meeting schedule daily?
							</Text>
							<Group grow noWrap>
								<Button
									className={`${classes.optionButton} ${
										dailySchedule === true && classes.selectedOptionButton
									}`}
									onClick={() => setDailySchedule(true)}
								>
									Yes
								</Button>
								<Button
									className={`${classes.optionButton} ${
										dailySchedule === false && classes.selectedOptionButton
									}`}
									onClick={() => setDailySchedule(false)}
								>
									No
								</Button>
							</Group>
						</div>
					</Stack>
				) : (
					<Group grow noWrap mt='lg'>
						<div style={{ flex: 1 }}>
							<Text className={classes.label}>
								Want to see tomorrow's meeting schedule daily?
							</Text>
							<Group grow noWrap>
								<Button
									className={`${classes.optionButton} ${
										dailySchedule === true && classes.selectedOptionButton
									}`}
									onClick={() => setDailySchedule(true)}
								>
									Yes
								</Button>
								<Button
									className={`${classes.optionButton} ${
										dailySchedule === false && classes.selectedOptionButton
									}`}
									onClick={() => setDailySchedule(false)}
								>
									No
								</Button>
							</Group>
						</div>
					</Group>
				)}

				<Button
					type='submit'
					fullWidth
					mt='lg'
					size='md'
					radius={'md'}
					style={{ boxShadow: 'none' }}
					loading={loading}
				>
					Lastly, connect your calendar
				</Button>
			</form>
		</Stack>
	);
};

export default UserInviteSetPreferences;
