import { IMeeting } from '../../interfaces/meeting';
import {
	Card,
	createStyles,
	Group,
	Text,
	Grid,
	Button,
	ActionIcon,
	AspectRatio,
	BackgroundImage,
	Stack,
	Tooltip,
	Progress,
	Overlay,
	Badge,
	CopyButton,
	Menu,
	Checkbox,
	Loader,
	Center,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createVideoThumbnail } from '../../helpers/data';
import { StatusBadge } from '../Badges/StatusBadge';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mantine/hooks';
import { flagsmithFeatureFlags } from '../constants';
import {
	destroyMeeting,
	getAttachmentsForMeeting,
	getMeetingInvitedUsers,
	updateMeeting,
} from '../../api/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser, currentUserReelays } from '../../Atoms/userAtoms';
import {
	currentMeeting,
	currentMeetingDistributionList,
	editScheduledMeetingInitialValues,
	editScheduledMeetingModalOpen,
	meetingVideoUploadProgressBars,
	previousPageURL,
	reloadMeetingsAtom,
	uploadingVideoAtom,
} from '../../Atoms/meetingAtom';
import { getMeetingHost, isMeetingExpired } from '../../helpers/meetings';
import { logger } from '../../helpers/logger';
import reactStringReplace from 'react-string-replace';
import { DateTime } from 'luxon';
import { removeHtmlTags } from '../../_utils/handy-functions';
import { VIDEO_UPLOAD_COMPLETED_PERCENTAGE } from '../meeting_table';
import {
	SpecialCollections,
	addMeetingToCollectionModalOpenedAtom,
	archiveCollectionAtom,
	bulkDeletingCollectionMeetings,
	bulkDeletingCollectionMeetingsSelection,
	meetingBeingAddedToCollection,
} from '../../Atoms/collections';
import PlatformIcon from '../PlatformIcon';
import { useLocation } from 'react-router-dom';
import { useCollections } from '../../customHooks/useCollections';
import { useFlags } from 'flagsmith/react';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { calculateTimeStamp } from '../../_utils/time';
import { Dispatch, SetStateAction, useState } from 'react';
import {
	faBoxArchive,
	faDownload,
	faEdit,
	faEllipsisVertical,
	faFileInvoice,
	faPlus,
	faShare,
	faTrash,
	faUserRobot,
} from '@fortawesome/pro-regular-svg-icons';
import { isMeetingOwnerOrProjectManager } from '../../helpers/auth';
import { modals } from '@mantine/modals';
import { downloadAttachment } from '../../helpers/attachments';
import { organizationSettings } from '../../Atoms/settings';
import { BotCreationStatus, BotStatus } from '../../interfaces/recallai';
import {
	removeBotFromCalendarEvent,
	scheduleBotForCalendarEvent,
} from '../../api/calendar';
import { getEnvForGleap } from '../../_utils/trackers';
import {
	upcomingMeetings,
	updateMeetingLibraryToggleAtom,
} from '../../Atoms/recallAI';

const useStyles = createStyles((theme) => ({
	card: {
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
		// height: 500,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	hoveredCard: {
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
		height: 500,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		'&:hover': {
			cursor: 'pointer',
		},
	},
	avatarGroup: {
		color: theme.colors['secondary-text'][0],
		fontWeight: 400,
		fontSize: 14,
		lineHeight: 20,
	},
	section: {
		borderBottom: `1px solid ${
			theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
		}`,
		padding: theme.spacing.md,
		flex: 3,
	},
	stats: {
		display: 'flex',
		justifyContent: 'space-around',
		padding: theme.spacing.xs,
		flex: 1,
	},
	like: {
		color: theme.colors.red[6],
	},
	label: {
		textTransform: 'uppercase',
		fontSize: theme.fontSizes.xs,
		fontWeight: 700,
	},
	timestampContainer: {
		backgroundColor: 'black',
		opacity: 0.8,
		color: 'white',
		marginBottom: '2.5%',
		marginRight: '1.5%',
		borderRadius: '4px',
		padding: 3,
	},
	textAndProgressBar: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
		color: theme.colors.gray[0],
		width: '90%',
		zIndex: 1000000,
	},
}));

const IMAGE_ASPECT_RATIO_NOMINATOR = 16;
const IMAGE_ASPECT_RATIO_DENOMINATOR = 9;
const TEXT_LINE_CLAMP_5_HEIGHT = 102.97;
const CONSERVATIVE_MAX_CHAR_COUNT_TEXT_LINE_CLAMP_5 = 220;
const THUMBNAIL_ROW_HEIGHT = 37;

interface Props {
	meeting: IMeeting;
	setMeetings: Dispatch<SetStateAction<IMeeting[]>>;
	searchValue?: string;
	// eslint-disable-next-line no-unused-vars
	handleGetMeetingsData?: ({ searchValue }: { searchValue: string }) => void;
	openSharingModal?: () => void;
}
const VerticalMeetingCard = ({
	meeting,
	setMeetings,
	searchValue,
	handleGetMeetingsData,
	openSharingModal,
}: Props) => {
	const { classes, theme } = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const {
		startAt,
		botMetadata,
		meetingDate,
		createdAt,
		publishedAt,
		videoMetadata,
		name,
		author,
		platform,
		thumbnailTime,
		id,
		summary,
		viewingDeadline,
		actionsCount,
		questionsCount,
		attachmentsCount,
		views,
		status,
		organizationID,
		owningUserID,
		friendlyID,
		recapOnly,
		sharingToken,
		organizer,
		botCreationStatus,
	} = meeting;
	const flags = useFlags([flagsmithFeatureFlags.meetingCollections]);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const user = useRecoilValue(currentUser);
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const { addMeetingsToCollection, removeMeetingFromCollection, collection } =
		useCollections(false);
	const isCollectionOwner = collection?.owningUserID === user?.id;
	const startAtTime = startAt || botMetadata?.joinAt;

	const isBelowMdBreakpoint = useMediaQuery('(max-width: 1024px)');
	const [allMeetings, setAllMeetings] = useRecoilState(currentUserReelays);
	// an expired meeting is when it is published and its viewing deadline is past.
	const meetingExpired =
		isMeetingExpired(viewingDeadline) && status === 'published';
	const isScheduledMeeting = status === 'scheduled';
	const videoUploadProgressBars = useRecoilValue(
		meetingVideoUploadProgressBars
	);
	const setEditScheduledMeetingModalOpen = useSetRecoilState(
		editScheduledMeetingModalOpen
	);
	const setEditScheduledMeetingInitialValues = useSetRecoilState(
		editScheduledMeetingInitialValues
	);
	const uploadingVideo = useRecoilValue(uploadingVideoAtom);
	const setAddMeetingToCollectionModalOpened = useSetRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const setMeetingToAdd = useSetRecoilState(meetingBeingAddedToCollection);
	const setMeeting = useSetRecoilState(currentMeeting);
	const setDistributionList = useSetRecoilState(currentMeetingDistributionList);
	const setPreviousURL = useSetRecoilState(previousPageURL);

	const isOnCollectionsPage = location.pathname.includes('collections');
	const cardDate = DateTime.fromISO(
		meetingDate ?? publishedAt ?? createdAt
	).toFormat('MM/dd/yyyy');

	const bulkDeletingMeetings = useRecoilValue(bulkDeletingCollectionMeetings);
	const setReloadMeetings = useSetRecoilState(reloadMeetingsAtom);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const [menuLoading, setMenuLoading] = useState(false);
	const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
		organizer,
		owningUserID,
		user.id,
		user.roles
	);

	const archiveCollection = useRecoilValue(archiveCollectionAtom);
	const [upcomingMeetingsState, setUpcomingMeetings] =
		useRecoilState(upcomingMeetings);
	const setUpdateMeetingLibraryToggle = useSetRecoilState(
		updateMeetingLibraryToggleAtom
	);

	const isArchived = archiveCollection?.content.some((c) => c.meetingID === id);
	const viewingArchived = collection?.name === SpecialCollections.Archived;
	const botNotCreated =
		meeting.botCreationStatus ===
		BotCreationStatus.NOT_CREATED_RESTRICTED_DOMAIN;
	const botDontJoin =
		botMetadata?.status === BotStatus.BotDeleted || botNotCreated;

	const isCalendarEvent = Boolean(botMetadata?.recallEventID);

	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);

	const highlightText = (text: string | undefined) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};

	const handleButtonClick = async () => {
		if (meetingExpired) {
			// update meeting status to 'review'
			const updatedMeeting = await updateMeeting(
				{
					status: 'review',
				},
				id,
				organizationID
			);
			setAllMeetings(
				allMeetings.map((meetingItem) => {
					if (meetingItem.id === id) return updatedMeeting.data.data;
					return meetingItem;
				})
			);
			return;
		} else if (isScheduledMeeting) {
			// open edit meeting modal
			setEditScheduledMeetingModalOpen(true);
			// set initial values to prefill form.
			setEditScheduledMeetingInitialValues({
				name: name,
				meetingLink: botMetadata?.meetingLink,
				joinAt: startAtTime,
				meetingID: id,
				botID: botMetadata?.id,
			});
		} else {
			// else navigate to view meeting
			setPreviousURL(location.pathname);
			let meetingUrl = `/meetings/${friendlyID}`;
			if (sharingToken.active) {
				meetingUrl += `?vt=${sharingToken.token}`;
			}
			navigate(meetingUrl);
		}
	};

	const meetingAnalytics = [
		{
			title: 'Actions',
			value: actionsCount,
		},
		{
			title: 'Questions',
			value: questionsCount,
		},
		{
			title: 'Attachments',
			value: attachmentsCount,
		},
	];

	const isDateYearCurrentYear = (date: string) => {
		// Replace isoDate with your ISO date
		const dateTime = DateTime.fromISO(date);

		// Get the current year
		const currentYear = DateTime.local().year;

		// Get the year from the dateTime object
		const yearFromISODate = dateTime.year;

		// Compare if they are the same
		return currentYear === yearFromISODate;
	};

	const handleRemoveFromCollection = async () => {
		try {
			await removeMeetingFromCollection(collection, id);
		} catch (err) {
			logger('error', 'Error removing meeting from collection', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully removed from the collection. Please try the operation again.`,
			});
		}
	};

	const scheduledMeetingInformation = [
		{
			title: 'Date',
			value: startAtTime
				? DateTime.fromISO(startAtTime).toFormat(
						isDateYearCurrentYear(startAtTime) ? 'LLL. dd' : 'LLL. dd, yyyy'
				  )
				: 'N/A',
		},
		{
			title: 'Time',
			value: startAtTime
				? DateTime.fromISO(startAtTime).toFormat('h:mm a')
				: 'N/A',
		},
		{
			title: 'Platform',
			value: (
				<CopyButton value={botMetadata?.meetingLink}>
					{({ copied, copy }) => (
						<Tooltip
							label={copied ? 'Copied' : `Copy ${botMetadata?.meetingLink}`}
							withinPortal
							withArrow
							position='top'
							zIndex={10000}
						>
							{platform ? (
								<PlatformIcon platform={platform} />
							) : (
								<Badge
									onClick={copy}
									style={{ cursor: 'pointer' }}
									variant={'filled'}
								>
									{platform}
								</Badge>
							)}
						</Tooltip>
					)}
				</CopyButton>
			),
		},
	];

	const handleArchiveMeeting = async () => {
		try {
			setMenuLoading(true);
			await addMeetingsToCollection(archiveCollection.id, [id]);
			if (setReloadMeetings) setReloadMeetings((prev: any) => !prev);
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleUnarchiveMeeting = async () => {
		try {
			setMenuLoading(true);
			removeMeetingFromCollection(archiveCollection, id, false);
			if (setReloadMeetings) setReloadMeetings((prev: any) => !prev);
			showSuccessNotification({
				message: `Meeting was successfully unarchived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleDeleteMeeting = async () => {
		modals.openConfirmModal({
			title: `Delete ${name}`,
			children: (
				<Text size='sm'>
					Are you sure you want to delete this meeting? This action is
					irreversible.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			onCancel: () => console.log('Cancel'),
			onConfirm: () => deleteMeeting(),
		});
	};

	const deleteMeeting = async () => {
		try {
			const res = await destroyMeeting(id, organizationID);
			showSuccessNotification({
				message: `Meeting was successfully deleted.`,
			});
			// re-fetch the meetings list so the page is full and the pagination is correct.
			handleGetMeetingsData({ searchValue });
			setReloadMeetings((prev) => !prev);
		} catch (err) {
			logger('error', 'Error deleting meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully deleted. Please try the operation again.`,
			});
		}
	};

	const handleShare = async () => {
		try {
			setMenuLoading(true);
			setMeeting(meeting);
			const res = await getMeetingInvitedUsers(id, organizationID);
			const invitedUsers = res?.data?.data?.invitedUsers || [];
			const filteredDistributionList = invitedUsers.filter(
				(user) => !user?.userlink?.presented
			);
			setDistributionList(filteredDistributionList);
			openSharingModal();
		} catch (err) {
			logger('error', 'Error sharing meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully shared. Please try the operation again.`,
			});
		} finally {
			setMenuLoading(false);
		}
	};

	const handleDownloadMinutes = async () => {
		try {
			setMenuLoading(true);
			const res = await getAttachmentsForMeeting(id, organizationID);
			const minutesDoc = res?.data?.data?.find(
				(attachment) =>
					attachment.type === 'minutes' ||
					attachment.fileName.includes('Minutes')
			);
			minutesDoc
				? downloadAttachment(minutesDoc, meeting.organizationID)
				: showFailureNotification({
						message: 'Sorry, no meeting minutes available for download.',
				  });
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to download the minutes. Please give it another try.',
			});
			logger('error', 'error downloading minutes:', error);
		} finally {
			setMenuLoading(false);
		}
	};

	const handleRecapOnly = async () => {
		try {
			setMenuLoading(true);
			const updatedMeetingRes = await updateMeeting(
				{
					recapOnly: !recapOnly,
				},
				id,
				organizationID
			);
			const updatedMeeting = updatedMeetingRes?.data?.data;
			setMeetings((prev) =>
				prev.map((m) =>
					m.id === id
						? {
								...m,
								recapOnly: updatedMeeting?.recapOnly,
						  }
						: m
				)
			);
			showSuccessNotification({
				message: `Meeting recap set to ${updatedMeeting?.recapOnly}.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the meeting. Please give it another try.',
			});
			logger('error', 'error updating meeting:', error);
		} finally {
			setMenuLoading(false);
		}
	};

	const handleBotJoin = async () => {
		try {
			if (botNotCreated) {
				showFailureNotification({
					title: 'Meeting Contains Restricted Email Domain',
					message: `Sorry, the bot for this meeting has not been created because your organization has restricted Reelay from joining meetings with certain email domains. Please contact your administrator if you think this is an error.`,
				});
				return;
			}
			if (botDontJoin) {
				// add bot to calendar event
				const res = await scheduleBotForCalendarEvent(
					botMetadata?.recallEventID,
					id,
					organizationID
				);
				const newBotMetadata = res?.data?.data;
				setMeetings((prev) =>
					prev.map((m) => {
						if (m.id === id) {
							return {
								...m,
								botMetadata: newBotMetadata || m.botMetadata,
							};
						}
						return m;
					})
				);
				showSuccessNotification({
					message: `Bot was successfully scheduled for this meeting.`,
				});
			} else {
				const botRes = await removeBotFromCalendarEvent(
					botMetadata.recallEventID,
					id,
					organizationID,
					botMetadata.eventID
				);
				if (botRes.status === 200) {
					showSuccessNotification({
						message: 'Bot was successfully removed from this meeting.',
					});
					const botMetadata = botRes?.data?.data?.botMetadata;
					setUpcomingMeetings((prev) =>
						prev.map((m) =>
							m.id === id
								? { ...m, botMetadata: botMetadata || m.botMetadata }
								: m
						)
					);
					setMeetings((prev) =>
						prev.map((m) =>
							m.id === id
								? { ...m, botMetadata: botMetadata || m.botMetadata }
								: m
						)
					);
					setTimeout(() => {
						setUpdateMeetingLibraryToggle((prev) => !prev);
					}, 1);
				}
			}
		} catch (error) {
			console.log('error', error);
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the meeting. Please give it another try.',
			});
			logger('error', 'error updating meeting:', error);
		} finally {
			setMenuLoading(false);
		}
	};

	const items = (
		isScheduledMeeting ? scheduledMeetingInformation : meetingAnalytics
	).map((stat) => {
		const clickable = stat?.title === 'Actions' || stat?.title === 'Questions';
		const tab = stat?.title === 'Actions' ? 'action' : 'question';

		return (
			<div
				key={stat.title}
				style={{
					width: '33%',
					cursor: clickable ? 'pointer' : 'default',
				}}
				onClick={() => {
					if (clickable) {
						navigate(`/meetings/${friendlyID}?tab=${tab}`);
					}
				}}
				onMouseEnter={(e) => {
					if (clickable) {
						e.currentTarget.style.color =
							theme.colors.primary[theme.primaryShade as number];
					}
				}}
				onMouseLeave={(e) => {
					if (clickable) {
						e.currentTarget.style.color = 'inherit';
					}
				}}
			>
				<Text
					weight={500}
					size='sm'
					align={'center'}
					display={'flex'}
					style={{ justifyContent: 'center' }}
					lineClamp={1}
				>
					{stat.value}
				</Text>
				<Text size='xs' color='dimmed' align={'center'}>
					{stat.title}
				</Text>
			</div>
		);
	});

	const titleText = searchValue.length ? highlightText(name) : name;
	const hostName = getMeetingHost(organizer, author);
	const hostText = searchValue.length ? highlightText(hostName) : hostName;

	const summaryText =
		typeof summary === 'string'
			? searchValue.length
				? highlightText(removeHtmlTags(summary))
				: removeHtmlTags(summary)
			: summary;

	return (
		<Grid.Col
			xs={6}
			sm={6}
			md={4}
			lg={4}
			xl={3}
			style={{
				...(isBelowMdBreakpoint
					? {
							display: 'flex',
							justifyContent: 'center',
					  }
					: {}),
			}}
		>
			<Card
				withBorder
				radius='md'
				p='md'
				shadow={'xl'}
				className={bulkDeletingMeetings ? classes.hoveredCard : classes.card}
				onClick={() => (bulkDeletingMeetings ? toggleRow(id) : null)}
				id={`VIS_ctf9nfdtmsxfgg2vmcv0_${getEnvForGleap()}`}
			>
				{/*Loading Overlay*/}
				{uploadingVideo && videoUploadProgressBars[id] && <Overlay blur={5} />}
				{uploadingVideo && videoUploadProgressBars[id] && (
					<Stack justify={'center'} className={classes.textAndProgressBar}>
						<Text
							size={'sm'}
							weight={500}
							style={{ zIndex: 100000 }}
							color={'primary-text'}
						>
							Uploading video... do not close page until it is finished!
						</Text>
						<Progress
							style={{ width: '100%', zIndex: 100000 }}
							color='violet'
							value={+videoUploadProgressBars[id]?.toFixed(0)}
							animate={
								+videoUploadProgressBars[id]?.toFixed(0) !==
								VIDEO_UPLOAD_COMPLETED_PERCENTAGE
							}
						/>
					</Stack>
				)}
				<Card.Section
					style={{
						cursor: recapOnly || status !== 'published' ? 'default' : 'pointer',
					}}
					onClick={() => {
						if (recapOnly || status !== 'published') return;
						setPreviousURL(location.pathname);
						navigate(`/meetings/${friendlyID}`);
					}}
				>
					<AspectRatio
						ratio={
							IMAGE_ASPECT_RATIO_NOMINATOR / IMAGE_ASPECT_RATIO_DENOMINATOR
						}
					>
						{recapOnly ? (
							<Center h={'100%'} w={'100%'} bg={'light-blue'}>
								<Stack
									justify={
										bulkDeletingMeetings ? 'space-between' : 'space-between'
									}
									align={'stretch'}
									h={'100%'}
									w={'100%'}
								>
									{bulkDeletingMeetings ? (
										<Group pt={'2.5%'} pl={'1.5%'} position={'left'}>
											<Checkbox checked={selection.includes(id)} />
										</Group>
									) : (
										<div
											style={{
												height: THUMBNAIL_ROW_HEIGHT,
											}}
										></div>
									)}

									<FontAwesomeIcon
										icon={faFileInvoice}
										size='4x'
										opacity={0.8}
									/>

									<Group
										position={'apart'}
										align={'center'}
										pt={'2.5%'}
										pr={'1.5%'}
										h={THUMBNAIL_ROW_HEIGHT}
									>
										<StatusBadge
											isThumbnailBadge={true}
											status={meetingExpired ? 'expired' : status}
											variant={'filled'}
											botStatus={botMetadata?.status}
											botCreationStatus={botCreationStatus}
										/>
										<Text fz={'xs'} className={classes.timestampContainer}>
											{calculateTimeStamp(videoMetadata?.duration)}
										</Text>
									</Group>
									{/*<Group position={'right'} align={'center'}>*/}
									{/* */}
									{/*</Group>*/}
								</Stack>
							</Center>
						) : (
							<BackgroundImage
								src={createVideoThumbnail(
									videoMetadata?.playbackID,
									thumbnailTime
								)}
							>
								<Stack
									justify={bulkDeletingMeetings ? 'space-between' : 'flex-end'}
									align={'stretch'}
									h={'100%'}
									w={'100%'}
								>
									{bulkDeletingMeetings ? (
										<Group pt={'2.5%'} pl={'1.5%'} position={'left'}>
											<Checkbox checked={selection.includes(id)} />
										</Group>
									) : null}

									<Group
										position={'apart'}
										align={'center'}
										pt={'2.5%'}
										pr={'1.5%'}
										h={THUMBNAIL_ROW_HEIGHT}
									>
										<StatusBadge
											isThumbnailBadge={true}
											status={meetingExpired ? 'expired' : status}
											variant={'filled'}
											botStatus={botMetadata?.status}
											botCreationStatus={botCreationStatus}
										/>
										<Text fz={'xs'} className={classes.timestampContainer}>
											{calculateTimeStamp(videoMetadata?.duration)}
										</Text>
									</Group>
									{/*<Group position={'right'} align={'center'}>*/}
									{/* */}
									{/*</Group>*/}
								</Stack>
							</BackgroundImage>
						)}
					</AspectRatio>
				</Card.Section>

				<Card.Section className={classes.section}>
					<Stack spacing={0}>
						<Group position='apart' noWrap>
							<Tooltip label={name} withinPortal={true} position={'bottom'}>
								<Text size={16} fw={700} lineClamp={1}>
									{titleText}
								</Text>
							</Tooltip>

							<Group position={'right'} spacing={0} noWrap>
								{menuLoading ? (
									<Loader size={'sm'} />
								) : // Show a menu only if it's not a scheduled meeting OR if its a scheduled meeting and the user is a host or PM
								!isScheduledMeeting ||
								  (isScheduledMeeting && isMeetingHostOrPM) ? (
									<Menu
										shadow='md'
										width={200}
										arrowPosition={'side'}
										position={'right-start'}
										id={`VIS_ctf9nfdtmsxfgg2vmcvg_${getEnvForGleap()}`}
									>
										<Menu.Target>
											<ActionIcon miw={0} w={14}>
												<FontAwesomeIcon
													icon={faEllipsisVertical}
													// className={classes.icon}
												/>
											</ActionIcon>
										</Menu.Target>
										<Menu.Dropdown>
											{/* Section: On Collections Page */}
											{collectionsEnabled && isOnCollectionsPage && (
												<>
													{isCollectionOwner &&
														!isScheduledMeeting &&
														!viewingArchived && (
															<Menu.Item
																onClick={handleRemoveFromCollection}
																icon={<FontAwesomeIcon icon={faTrash} />}
															>
																Remove from collection
															</Menu.Item>
														)}
													{collection.name === SpecialCollections.Archived &&
														!isScheduledMeeting && (
															<Menu.Item
																icon={<FontAwesomeIcon icon={faBoxArchive} />}
																onClick={() =>
																	isArchived
																		? handleUnarchiveMeeting()
																		: handleArchiveMeeting()
																}
															>
																{isArchived ? 'Unarchive' : 'Archive'}
															</Menu.Item>
														)}
												</>
											)}

											{/* Section: Scheduled Meeting */}
											{isScheduledMeeting && (
												<>
													{isMeetingHostOrPM && (
														<Menu.Item
															icon={<FontAwesomeIcon icon={faUserRobot} />}
															onClick={handleBotJoin}
														>
															{botDontJoin
																? 'Change to join'
																: "Change to don't join"}
														</Menu.Item>
													)}
													{isMeetingHostOrPM && !isCalendarEvent && (
														<Menu.Item
															icon={<FontAwesomeIcon icon={faEdit} />}
															onClick={handleButtonClick}
														>
															Update
														</Menu.Item>
													)}
												</>
											)}

											{/* Section: Not on Collections Page */}
											{!isOnCollectionsPage && (
												<>
													{collectionsEnabled && !isScheduledMeeting && (
														<Menu.Item
															icon={<FontAwesomeIcon icon={faPlus} />}
															onClick={() => {
																setAddMeetingToCollectionModalOpened(true);
																setMeetingToAdd(meeting);
															}}
														>
															Add to a collection
														</Menu.Item>
													)}
													{!isScheduledMeeting && (
														<Menu.Item
															icon={<FontAwesomeIcon icon={faBoxArchive} />}
															onClick={() =>
																isArchived
																	? handleUnarchiveMeeting()
																	: handleArchiveMeeting()
															}
														>
															{isArchived ? 'Unarchive' : 'Archive'}
														</Menu.Item>
													)}
													{isMeetingHostOrPM && (
														<Menu.Item
															icon={<FontAwesomeIcon icon={faTrash} />}
															onClick={handleDeleteMeeting}
														>
															Delete
														</Menu.Item>
													)}
												</>
											)}

											{/* Section: Common Items */}
											{sharingEnabled &&
												isMeetingHostOrPM &&
												!isScheduledMeeting && (
													<Menu.Item
														icon={<FontAwesomeIcon icon={faShare} />}
														onClick={handleShare}
													>
														Share
													</Menu.Item>
												)}
											{isMeetingHostOrPM && !botDontJoin && (
												<Menu.Item
													icon={<FontAwesomeIcon icon={faFileInvoice} />}
													onClick={handleRecapOnly}
												>
													{recapOnly
														? 'Change to recording'
														: 'Change to recap only'}
												</Menu.Item>
											)}
											{status === 'published' && (
												<Menu.Item
													icon={<FontAwesomeIcon icon={faDownload} />}
													onClick={handleDownloadMinutes}
												>
													Download minutes
												</Menu.Item>
											)}
										</Menu.Dropdown>
									</Menu>
								) : null}
							</Group>
						</Group>
						<Group
							position={'left'}
							spacing={8}
							noWrap
							className={classes.avatarGroup}
							mih={28}
						>
							<Text>{hostText}</Text>
							{!isScheduledMeeting && <Text size={'lg'}>&#8226;</Text>}
							{!isScheduledMeeting && <Text size={'sm'}>{cardDate}</Text>}
						</Group>
						<Text
							pt={'xs'}
							lineClamp={5}
							color={'primary-text'}
							size={12}
							h={
								summary === null ||
								summaryText.length <
									CONSERVATIVE_MAX_CHAR_COUNT_TEXT_LINE_CLAMP_5
									? TEXT_LINE_CLAMP_5_HEIGHT
									: 'auto'
							}
						>
							{isScheduledMeeting
								? `Reelay is scheduled to join your ${name} meeting on ${DateTime.fromISO(
										startAtTime
								  ).toFormat('MMMM dd, yyyy')} at ${DateTime.fromISO(
										startAtTime
								  ).toFormat(
										'h:mm a'
								  )}. If you need to make any updates to the meeting you can do so by clicking on the menu button above.`
								: summary
								? summaryText
								: 'No summary yet.'}
						</Text>
					</Stack>
				</Card.Section>

				<Card.Section className={classes.stats}>{items}</Card.Section>
			</Card>
		</Grid.Col>
	);
};

export default VerticalMeetingCard;
