import React from 'react';
import { Badge } from '@mantine/core';

export const RoleBadge = ({ role }: { role: string }) => {
	const getRoleBadge = () => {
		switch (role) {
			case 'member':
				return (
					<Badge color={'violet'} variant={'light'} size={'xs'}>
						{role}
					</Badge>
				);
			case 'admin':
				return (
					<Badge color={'red'} variant={'light'} size={'xs'}>
						{role}
					</Badge>
				);
			case 'superadmin':
				return (
					<Badge color={'green'} variant={'light'} size={'xs'}>
						{role}
					</Badge>
				);
			case 'create-meeting':
				return (
					<Badge color={'yellow'} variant={'light'} size={'xs'}>
						{role}
					</Badge>
				);
			case 'invite-to-organization':
				return (
					<Badge color={'gray'} variant={'light'} size={'xs'}>
						{role}
					</Badge>
				);
			case 'project-manager':
				return (
					<Badge variant={'light'} size={'xs'}>
						{role}
					</Badge>
				);
		}
	};
	return getRoleBadge();
};
