import React, { useState } from 'react';
import {
	Button,
	Center,
	createStyles,
	Group,
	Modal,
	PinInput,
	Space,
	Stack,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { ReelayLoader } from '../../components/ReelayLoader';
import { ReelayIcon } from '../../components/ReelayIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import CreateUserModal from '../../components/create_user_modal';
import { getCurrentYear } from '../../helpers/data';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	authTokenExpirationTime,
	originalURL,
	reelayLoader,
	userAuthenticated as userAuthenticatedAtom,
} from '../../Atoms/auth';
import { useForm } from '@mantine/form';
import {
	getCurrentOrganization,
	getCurrentUser,
	getUsersByOrg,
	loginWithTheCode,
	registerUser,
	sendAuthCode,
} from '../../api/api';
import { logger } from '../../helpers/logger';
import { isAdmin } from '../../helpers/auth';
import {
	user as UserAtom,
	userOrganizationMembers,
	userOrganization as OrganizationAtom,
} from '../../Atoms/userAtoms';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { getOrganizationBrand } from '../../api/settings';
import { organizationBrand } from '../../Atoms/settings';
import TermsOfUseCheckbox from '../../components/Checkboxes/TermsOfUseCheckbox';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import OTPInput from '../loginpage/components/OTPInput';
import { privacyLink, termsLink } from '../../components/constants';

const useStyles = createStyles((theme) => ({
	page: {
		background:
			'linear-gradient(180deg, rgba(79, 0, 245, 0.12) 0%, rgba(0, 128, 245, 0.12) 100%)',
		height: '100vh',
		verticalAlign: 'center',
	},
	label: {
		textAlign: 'center',
		width: '352px',
	},

	controls: {
		[theme.fn.smallerThan('xs')]: {
			flexDirection: 'column-reverse',
		},
		width: '352px',
	},

	control: {
		[theme.fn.smallerThan('xs')]: {
			width: '100%',
			textAlign: 'center',
		},
		width: '352px',
		height: '42px',
		fontSize: '16px',
	},

	copy: {
		fontSize: '12px',
		lineHeight: '22px',
		color: theme.colors.primary[5],
	},

	welcome: {
		fontSize: '32px',
		fontWeight: 'normal',
		lineHeight: '28px',
		marginTop: '40px',
	},
}));
export const CreateAccount = () => {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const loggingIn = useRecoilValue(reelayLoader);
	const setAuthStatus = useSetRecoilState(userAuthenticatedAtom);
	const setAuthTokenExpirationTime = useSetRecoilState(authTokenExpirationTime);
	const setOrganization = useSetRecoilState(OrganizationAtom);
	const setOrganizationMembers = useSetRecoilState(userOrganizationMembers);
	const setCurrentUser = useSetRecoilState(UserAtom);
	const originalUrl = useRecoilValue(originalURL);
	const setBrand = useSetRecoilState(organizationBrand);
	const [loading, setLoading] = useState(false);
	const [authCodeSent, setAuthCodeSent] = useState(false);
	const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);

	const form = useForm({
		initialValues: {
			email: '',
			firstName: '',
			lastName: '',
			agreed_with_terms: false,
		},
		validate: {
			firstName: (value) => (value.length ? null : 'Invalid first name'),
			lastName: (value) => (value.length ? null : 'Invalid last name'),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
		},
	});

	const authForm = useForm({
		initialValues: {
			authCode: '',
		},
		validate: {
			authCode: (value) => {
				const regex = /^[0-9]+$/;
				if (!regex.test(value) || value.length !== 6) {
					return 'Please enter a valid 6-digit code.';
				}
			},
		},
	});

	const handleSubmit = async (values) => {
		try {
			// create user
			const newUser = await registerUser(values);
			// send auth code
			const res = await sendAuthCode(values.email);
			showSuccessNotification({
				message: 'Check your email for a one-time password to login to Reelay.',
			});
			setAuthCodeSent(true);
		} catch (error) {
			if (
				error?.response?.data === 'User with that email address already exists'
			) {
				// send auth code
				const res = await sendAuthCode(values.email);
				setAuthCodeSent(true);
				showSuccessNotification({
					message:
						'Check your email for a one-time password to login to Reelay.',
				});
			}
		}
	};

	const handleAuthCode = async (values) => {
		try {
			setLoading(true);
			const res = await loginWithTheCode(values.authCode, form.values.email);
			if (res instanceof Error) {
				showFailureNotification({
					title: 'Verification code failed!',
					message: 'Please try logging in again or in a different way.',
				});
				return res;
			}
			// store sesion token and other auth fields as normal
			setAuthStatus(true);
			setAuthTokenExpirationTime(res?.expiresAt);
			showSuccessNotification({
				message: 'You were logged in!',
			});
			const currentUserRequest = await handleGetCurrentUser();
			logger('info', 'Current User Request', currentUserRequest);
			const { id, email, firstName, lastName, currentOrganizationID } =
				currentUserRequest;

			// set brand if it exists
			const brandData = await getOrganizationBrand(currentOrganizationID);
			if (brandData?.value?.color_primary) {
				setBrand(brandData.value);
				localStorage.setItem('brand', JSON.stringify(brandData.value));
			}

			if (!currentUserRequest.roles.contains('support' || 'masteradmin')) {
				await handleGetCurrentOrganization(
					currentUserRequest.currentOrganizationID
				);
			}
			logger('info', 'Login was successful', currentUserRequest);

			const startingPath =
				typeof originalUrl === 'string' &&
				!['/login', '/signup', '', '/', 'new-account'].includes(originalUrl)
					? originalUrl.length > 1
						? originalUrl
						: '/meetings'
					: isAdmin(currentUserRequest.roles)
					? '/admin/meetings'
					: '/meetings';
			logger('info', 'Starting path', startingPath);
			navigate(startingPath);
			return;
		} catch (err) {
			logger('error', 'Error logging in user', err);
			showFailureNotification({
				message: 'There was an error logging you in. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	};

	const resendAuthCode = (email: string) => {
		setLoading(true);
		try {
			sendAuthCode(email)
				.then((response) => {
					if (response?.success) {
						setLoading(false);
						setAuthCodeSent(true);
						showSuccessNotification({
							message:
								'Check your email for a one-time password to login to Reelay.',
						});
						logger('info', 'One time password sent was successful', response);
						return response;
					} else {
						logger('error', 'Error sending OTP', response);
						setLoading(false);
						showFailureNotification({
							message:
								'Sorry, there was an error sending the one-time password. Please try again.',
						});
					}
				})
				.catch((err) => {
					logger('error', 'Error sending OTP', err);
					setLoading(false);
					showFailureNotification({
						message:
							'Sorry, there was an error sending the one-time password. Please try again.',
					});
				});
		} catch (err) {
			logger('error', 'Error sending OTP', err);
		}
	};

	const handleGetCurrentUser = async () => {
		try {
			const user = await getCurrentUser();
			setLoading(false);
			setCurrentUser(user);
			const { currentOrganizationID } = user;
			console.log('getUserByOrgs 5 FIRES ---------------------------------');
			const usersResponse = await getUsersByOrg(currentOrganizationID);
			const users = usersResponse;
			setOrganizationMembers(users);

			return user;
		} catch (err) {
			logger('error', 'Error getting current user', err);
		}
	};

	const handleGetCurrentOrganization = async (organizationID: string) => {
		try {
			const currentOrg = await getCurrentOrganization(organizationID);
			setOrganization(currentOrg.data.data);

			// set brand if it exists
			const orgID = currentOrg.data.data.id;
			const brandData = await getOrganizationBrand(orgID);
			if (brandData?.value?.color_primary) {
				setBrand(brandData.value);
				localStorage.setItem('brand', JSON.stringify(brandData.value));
			}

			return currentOrg.data.data;
		} catch (err) {
			logger('error', 'Error getting current organization', err);
		}
	};

	return (
		<Center className={classes.page}>
			<Stack>
				<Space h='xl' />
				<Space h='xl' />

				<Stack align={'center'}>
					{loggingIn ? <ReelayLoader /> : <ReelayIcon />}
					<Space h='xl' />
					<Title align={'center'} order={1} weight={400} color={'primary-text'}>
						{authCodeSent ? 'Enter Verification Code' : 'Create an Account'}
					</Title>
					{authCodeSent ? (
						<Text
							maw={370}
							align={'center'}
							color={'secondary-text'}
							size={'sm'}
							fs={'italic'}
						>
							A 6-digit verification code has been sent to the email you
							registered with. Please enter it below.
						</Text>
					) : null}
					<Space h='sm' />
					<Center>
						{authCodeSent ? (
							<OTPInput
								form={form}
								authForm={authForm}
								resendButtonLoading={loading}
								verifyButtonLoading={verifyButtonLoading}
								handleAuthCode={handleAuthCode}
								handleSendAuthCode={resendAuthCode}
								handleNavigateBack={() => setAuthCodeSent(false)}
							/>
						) : (
							<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
								<Stack>
									<TextInput
										placeholder='First name'
										className={classes.label}
										required
										{...form.getInputProps('firstName')}
										radius='md'
										size='lg'
									/>
									<TextInput
										placeholder='Last name'
										className={classes.label}
										required
										{...form.getInputProps('lastName')}
										radius='md'
										size='lg'
									/>
									<TextInput
										placeholder='Email address'
										className={classes.label}
										required
										type={'email'}
										{...form.getInputProps('email')}
										radius='md'
										size='lg'
									/>
									<TermsOfUseCheckbox form={form} />
									<Group
										position='right'
										mt='xl'
										mb='md'
										className={classes.controls}
									>
										<Button
											type='submit'
											className={classes.control}
											loading={loading}
											leftIcon={
												<FontAwesomeIcon
													style={{ cursor: 'pointer', float: 'right' }}
													icon={faGlobe}
												/>
											}
											radius={'md'}
										>
											<Text ml={'sm'}>Create</Text>
										</Button>
									</Group>
								</Stack>
							</form>
						)}
					</Center>
				</Stack>
				<Space h={'sm'} />
				<Stack className={classes.copy} align={'center'}>
					<Text>v{process.env.REACT_APP_VERSION}</Text>
					<Text>Copyright © {getCurrentYear()} Reelay Meetings, Inc.</Text>
					<Text>
						<a
							style={{
								textDecoration: 'none',
								color: 'black',
								fontWeight: 'bold',
							}}
							rel={'noopener noreferrer'}
							target={'_blank'}
							href={termsLink}
						>
							Terms of Use
						</a>{' '}
						|{' '}
						<a
							style={{
								textDecoration: 'none',
								color: 'black',
								fontWeight: 'bold',
							}}
							rel={'noopener noreferrer'}
							target={'_blank'}
							href={privacyLink}
						>
							{' '}
							Privacy Policy
						</a>
					</Text>
				</Stack>
			</Stack>
		</Center>
	);
};
