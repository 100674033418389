import { createStyles, Grid, Stack, Title } from '@mantine/core';
import { UserCard } from './components/UserCard';
import { PersonalInformationCard } from './components/PersonalInformationCard';
import AddressCard from './components/AddressCard';
import MeetingMinutesCard from './components/MeetingMinutesCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import { organizationSettings as organizationSettingsAtom } from '../../../../Atoms/settings';
import UserSettingSwitch from './components/UserSettingSwitch';
import EmailDomainsCard from '../../../admin/edit_organization/Tabs/OrganizationOverview/components/EmailDomainsCard';
import { isUserAdmin } from '../../../../helpers/auth';
import { currentUser } from '../../../../Atoms/userAtoms';

const useStyles = createStyles(() => ({
	outerContainer: {
		overflowY: 'auto',
	},
}));

export const MyProfile = () => {
	const { classes } = useStyles();
	const [organizationSettings, setOrganizationSettings] = useRecoilState(
		organizationSettingsAtom
	);
	const user = useRecoilValue(currentUser);
	const isAdmin = isUserAdmin(user.roles);
	const { sharing } = organizationSettings;

	return (
		<div>
			<Grid>
				<Grid.Col span={3}>
					<Stack p={'md'} h={'100%'} className={classes.outerContainer}>
						<UserCard />
					</Stack>
				</Grid.Col>
				<Grid.Col span={9}>
					<Stack p={'md'} h={'100%'} className={classes.outerContainer}>
						<PersonalInformationCard />
						<AddressCard />
						<MeetingMinutesCard />
						{!sharing ? null : (
							<UserSettingSwitch
								settingKey='sharing'
								label='Sharing Default'
								description='Enable this setting to have new meetings created with sharing preference set to'
								successMessage='You have successfully changed the sharing setting.'
								failureMessage='Sorry, there was an error changing the sharing setting. Please try again.'
								sharingOptionText='Anyone with Link'
								defaultOptionText='Invite only'
							/>
						)}
						<UserSettingSwitch
							settingKey='rollup_email'
							label='Daily Meeting Rollup Email'
							description="Enable this setting to receive a daily email at 4 PM with a list of tomorrow's upcoming meetings where Reelay is present."
							successMessage='You have successfully changed the daily meeting rollup email setting.'
							failureMessage='Sorry, there was an error changing the daily meeting rollup email setting. Please try again.'
						/>
						{isAdmin && (
							<>
								<Title order={4}>Bot Restricted Email Domains</Title>
								<EmailDomainsCard
									organizationSettings={organizationSettings}
									setOrganizationSettings={setOrganizationSettings}
									settingKey='bot_restricted_email_domains'
									successAddMessage={(value) =>
										`You have successfully added a new bot restricted email domain: ${value}.`
									}
									successRemoveMessage={(value) =>
										`You have successfully removed bot restricted email domain: ${value}.`
									}
									description='Reelay will not join calendar events with attendees that contain these email domains.'
									placeholder='enter a email domain ex. reelay.com'
								/>
							</>
						)}
					</Stack>
				</Grid.Col>
			</Grid>
		</div>
	);
};

export default MyProfile;
