import React, { useState } from 'react';
import { Group } from '@mantine/core';
import CollectionsOverview from './components/CollectionsOverview';
import EmptyCollectionsPage from './components/EmptyCollectionsPage';
import AddCollectionModal from './components/AddCollectionModal';
import RenameCollectionModal from './components/RenameCollectionModal';
import { useCollections } from '../../customHooks/useCollections';

export default function Collections() {
	const { collections } = useCollections();
	const [opened, setOpened] = useState(false);

	return (
		<>
			<Group grow position='apart' h={'100%'}>
				{collections.length ? (
					<CollectionsOverview setOpened={setOpened} />
				) : (
					<EmptyCollectionsPage setOpened={setOpened} />
				)}
			</Group>
			<AddCollectionModal setOpened={setOpened} opened={opened} />
			<RenameCollectionModal />
		</>
	);
}
