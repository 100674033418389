import React, { memo, useCallback, useMemo } from 'react';
import { PickerInline, PickerOverlay } from 'filestack-react';
import { filestackModalOpenedAtom } from '../../Atoms/filestack';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	PickerDisplayMode,
	PickerFileMetadata,
	PickerResponse,
} from 'filestack-js';

const FilestackPicker = ({
	handleUploadDone,
	handleUploadStarted,
}: {
	handleUploadDone: (result: PickerResponse) => void;
	handleUploadStarted: (file: PickerFileMetadata) => void;
}) => {
	const setFilestackModalOpened = useSetRecoilState(filestackModalOpenedAtom);

	const handleError = useCallback((err: any) => {
		console.log('onError err', err);
	}, []);

	const pickerOptions = useMemo(
		() => ({
			onClose: () => {
				setFilestackModalOpened(false);
			},
			onFileUploadStarted: (e: any) => {
				// handleUploadStarted(e);
			},
			onUploadStarted: (e: any) => {
				handleUploadStarted(e);
			},
			onFileUploadProgress: (event: any) => {
				// console.log('onFileUploadProgress event', event);
			},
			maxFiles: 1,
			fromSources: [
				'local_file_system',
				// 'url',
				'googledrive',
				'dropbox',
				'onedrive',
			],
			accept: ['video/*', 'audio/*'],
			// token: storedOAuthToken,
			hideModalWhenUploading: true,
			disableTransformer: true,
			uploadInBackground: true,
			// displayMode: PickerDisplayMode.inline,
		}),
		[setFilestackModalOpened]
	);

	return (
		<PickerInline
			apikey={process.env.REACT_APP_FILESTACK_API_KEY ?? ''}
			// onSuccess={handleSuccess}
			onUploadDone={handleUploadDone as any}
			onError={handleError}
			pickerOptions={pickerOptions}
		/>
	);
};

export default memo(FilestackPicker);
