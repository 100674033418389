import React, { useEffect } from 'react';
import {
	createStyles,
	Title,
	Text,
	Button,
	Container,
	Group,
} from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../Atoms/userAtoms';
import { logger } from '../../helpers/logger';

const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	label: {
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 220,
		lineHeight: 1,
		marginBottom: theme.spacing.xl,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[4]
				: theme.colors.gray[2],

		[theme.fn.smallerThan('sm')]: {
			fontSize: 120,
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 38,

		[theme.fn.smallerThan('sm')]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 500,
		margin: 'auto',
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl,
	},
}));

export function ServerError({ error, resetErrorBoundary }) {
	console.log('Error Boundary Server Error', error);
	logger(
		'error',
		'App crashed, Error Boundary triggered. See data for error.',
		{ error }
	);
	const { classes } = useStyles();

	return (
		<Container className={classes.root}>
			<div className={classes.label}>Uh-oh</div>
			<Title className={classes.title}>Something went wrong.</Title>
			<Text
				color='dimmed'
				size='lg'
				align='center'
				className={classes.description}
			>
				Our servers could not handle your request. Don&apos;t worry, our
				development team was already notified. Try refreshing the page.
			</Text>
			<Group position='center'>
				<Button variant='subtle' size='md' onClick={resetErrorBoundary}>
					Take me back to home page
				</Button>
			</Group>
		</Container>
	);
}
