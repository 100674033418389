import React, { useEffect, useRef, useState } from 'react';
import { createStyles, Loader, Modal, Group } from '@mantine/core';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import WebViewer from '@pdftron/webviewer';
import { IAttachment, userOrganization } from '../../../Atoms/userAtoms';
import { sendAnalyticEvent } from '../../../api/api';
import { useRecoilValue } from 'recoil';
import { logger } from '../../../helpers/logger';

interface Props {
	viewingPreview: any;
	setViewingPreview: any;
	currentFilePreview: any;
	files: any;
	filePreviews: any;
}

const useStyles = createStyles(() => ({
	webViewer: {
		height: '900px',
	},
}));
export const FilePreviewModal = ({
	files,
	viewingPreview,
	setViewingPreview,
	currentFilePreview,
	filePreviews,
}: Props) => {
	const { classes } = useStyles();
	const viewerDiv = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState(true);
	const fileType =
		files[currentFilePreview]?.type || files[currentFilePreview]?.mimeType;
	const filePath =
		filePreviews[currentFilePreview]?.uri ||
		files[currentFilePreview]?.attachmentURL;
	const organization = useRecoilValue(userOrganization);

	useEffect(() => {
		const attachment = files[currentFilePreview];
		// send view attachment event
		sendAnalyticEvent(
			{
				name: 'reelay.attachment',
				sourceID: attachment?.meetingID,
				targetID: attachment?.id,
				data: {
					type: 'view',
					timestamp: new Date().toISOString(),
				},
			},
			organization.id
		);

		setTimeout(() => {
			if (
				(filePreviews[currentFilePreview] || files[currentFilePreview]) &&
				!['image/png', 'image/jpeg'].includes(fileType)
			) {
				// eslint-disable-next-line new-cap
				WebViewer(
					{
						// path:       '../../../../public/lib',
						path: 'lib',
						initialDoc: filePath,
						// initialDoc: 'https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf',
					},
					viewerDiv.current as HTMLDivElement
				)
					.then((instance) => {
						logger('info', 'WebViewer loaded', { instance });
					})
					.catch((error) => {
						console.log('pdf webviewer error', error);
						logger('error', 'WebViewer failed to load', { error });
					})
					.finally(() => setLoading(false));
			}
		}, 1);
	}, []);

	return (
		<Modal
			centered
			radius={'md'}
			padding={25}
			style={{ height: 'auto', maxHeight: '90%' }}
			size={'70%'}
			opened={viewingPreview}
			onClose={() => setViewingPreview(false)}
			title={
				files[currentFilePreview]?.name
					? `Preview - ${files[currentFilePreview].name}`
					: 'Preview'
			}
		>
			{fileType === 'image/png' || fileType === 'image/jpeg' ? (
				<DocViewer
					documents={[
						filePreviews[currentFilePreview] ||
							filePreviews[files[currentFilePreview]?.id],
					]}
					pluginRenderers={DocViewerRenderers}
					// pluginRenderers={[ PDFRenderer, PNGRenderer, JPGRenderer ]}
					config={{
						header: {
							disableHeader: true,
						},
					}}
				/>
			) : (
				<div ref={viewerDiv} className={classes.webViewer}>
					{loading && (
						<Group mt={'50%'} position={'center'} align={'center'}>
							<Loader size={'xl'} />
						</Group>
					)}
				</div>
			)}
		</Modal>
	);
};
