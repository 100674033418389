import {
	Stack,
	Title,
	Group,
	Paper,
	createStyles,
	ScrollArea,
	Table,
	ActionIcon,
	Menu,
	rem,
	Loader,
	Text,
} from '@mantine/core';
import { IMeeting } from '../../../interfaces/meeting';
import { IconDots, IconRobot, IconTrash } from '@tabler/icons-react';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { getAllLiveMeetings } from '../../../api/playPauseRecording';
import { currentUser } from '../../../Atoms/userAtoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { organizations } from '../../../Atoms/organizationAtom';
import { transformMeetingDataForLiveBotsTable } from '../../../components/SupportMeetingsTable/helper';
import { getAllOrganizations, removeBotFromCall } from '../../../api/api';
import { logger } from '../../../helpers/logger';

const useStyles = createStyles((theme) => ({
	outerContainer: {
		height: 'auto',
	},
	topContainer: {},
	bottomContainer: {
		flex: 2,
		overflowY: 'auto',
	},
}));

const ths = (
	<tr>
		<th>Meeting Name</th>
		<th>Organization Name</th>
		<th>Owner</th>
		<th>Meeting Date</th>
		<th>Last Updated</th>
		<th>Bot Status</th>
		<th></th>
	</tr>
);

export default function LiveBots() {
	const { classes } = useStyles();
	const setOrganizations = useSetRecoilState(organizations);
	const user = useRecoilValue(currentUser);
	const [loading, setLoading] = useState(true);
	const [meetings, setMeetings] = useState<IMeeting[]>([]);
	const orgs = useRecoilValue(organizations);

	const tableData = transformMeetingDataForLiveBotsTable(meetings, orgs);

	const removeBotFromMeeting = async (meeting: IMeeting) => {
		try {
			const botID = meeting?.botMetadata?.id;
			const recallBotID = meeting?.botMetadata?.recallBotID;
			const res = await removeBotFromCall({ botID });
			if (res?.data.data.status === 'success') {
				setMeetings(
					meetings.filter((m) => m.botMetadata?.recallBotID !== recallBotID)
				);
				showSuccessNotification({
					message: 'Bot successfully removed from meeting.',
				});
			} else {
				throw new Error('Failed to remove bot from meeting. Please try again.');
			}
		} catch (error) {
			console.log(error);
			showFailureNotification({
				message: 'Failed to remove bot from meeting.',
			});
		}
	};

	const getLiveBots = async () => {
		try {
			const organizations = await getAllOrganizations();
			logger('info', 'Retrieved organizations', organizations);
			setOrganizations(organizations.data.data);
			const liveMeetings = await getAllLiveMeetings(
				user?.currentOrganizationID
			);
			setMeetings(liveMeetings);
		} catch (error) {
			console.log(error);
			showFailureNotification({
				message: 'Failed to get live meetings, please try refreshing the page.',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getLiveBots();
	}, []);

	const rows = tableData.map((meeting) => (
		<tr key={meeting.id}>
			<td>{meeting.name}</td>
			<td>{meeting.organizationName}</td>
			<td>{meeting.owner}</td>
			<td>{meeting.date}</td>
			<td>{meeting.lastUpdated}</td>
			<td>{meeting.status}</td>
			<td>
				<Group>
					<Menu
						transitionProps={{ transition: 'pop' }}
						withArrow
						position='bottom-end'
						withinPortal
					>
						<Menu.Target>
							<ActionIcon variant='subtle' color='gray'>
								<IconDots
									style={{ width: rem(16), height: rem(16) }}
									stroke={1.5}
								/>
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								icon={
									<IconRobot
										style={{ width: rem(16), height: rem(16) }}
										stroke={1.5}
									/>
								}
								color='red'
								onClick={() => removeBotFromMeeting(meeting)}
							>
								Remove Bot
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			</td>
		</tr>
	));

	return loading ? (
		<Stack align={'center'} mt={'xl'}>
			<Loader variant='bars' />
			<Text>Loading...</Text>
		</Stack>
	) : (
		<Stack
			spacing={0}
			justify={'flex-start'}
			className={classes.outerContainer}
		>
			<Group position={'left'} my={'lg'} className={classes.topContainer}>
				<Title order={3}>Live Meetings with Bots</Title>
			</Group>
			<Paper
				className={classes.bottomContainer}
				radius='md'
				shadow='md'
				withBorder
			>
				{meetings.length ? (
					<ScrollArea h={'100%'} offsetScrollbars>
						<Table striped highlightOnHover>
							<thead>{ths}</thead>
							<tbody>{rows}</tbody>
						</Table>
					</ScrollArea>
				) : (
					<Text p={'md'}>No live meetings with bots.</Text>
				)}
			</Paper>
		</Stack>
	);
}
