/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { Modal, Button, TextInput, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useRecoilState } from 'recoil';
import { User, userOrganizationMembers } from '../../../Atoms/userAtoms';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
	roles,
} from '../../constants';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { logger } from '../../../helpers/logger';
import {
	inviteUserToOrganization,
	updateOrganizationUser,
} from '../../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

interface Props {
	member: User;
	opened: boolean;
	setOpened: (opened: boolean) => void;
}
export default function EditUserModal({ member, opened, setOpened }: Props) {
	const [users, setUsers] = useRecoilState(userOrganizationMembers);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();

	const form = useForm({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			roles: [],
		},
		validate: {
			firstName: (value) => (value?.length ? null : 'Invalid first name'),
			lastName: (value) => (value?.length ? null : 'Invalid last name'),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			roles: (value) => (value?.length ? null : 'A role is required.'),
		},
	});

	useEffect(() => {
		form.setValues({
			firstName: member.firstName,
			lastName: member.lastName,
			email: member.email,
			roles: member.roles,
		});
	}, [member]);

	async function updateUser(data: any) {
		setLoading(true);
		try {
			const updatedUser = await updateOrganizationUser(
				data,
				member.id,
				member.currentOrganizationID
			);
			const newUser = updatedUser.data.data;
			logger('info', 'User was successfully updated', updatedUser);
			const updatedUsers = users.map((user) =>
				user.id === newUser.id ? newUser : user
			);
			setUsers(updatedUsers);
			showSuccessNotification({
				message: 'User was successfully invited! Great work!',
			});
		} catch (err: unknown) {
			logger('error', 'Error inviting user to organization', err);
			showFailureNotification({
				message:
					'Sorry, there was an error when inviting a user. Please try again.',
			});
		} finally {
			setLoading(false);
			setOpened(false);
		}
	}

	function compareArrays(array1, array2) {
		if (array1.length !== array2.length) {
			return false;
		}

		// Sort both arrays to ensure the elements are in the same order
		const sortedArray1 = array1.slice().sort();
		const sortedArray2 = array2.slice().sort();

		// Iterate over the elements and compare them
		for (let i = 0; i < sortedArray1.length; i++) {
			if (sortedArray1[i] !== sortedArray2[i]) {
				return false;
			}
		}
		return true;
	}

	return (
		<Modal
			opened={opened}
			centered={true}
			onClose={() => setOpened(false)}
			title='Edit Member'
		>
			<form onSubmit={form.onSubmit((values) => updateUser(values))}>
				<TextInput
					mb='sm'
					label={'First Name'}
					{...form.getInputProps('firstName')}
				/>
				<TextInput
					mb='sm'
					label={'Last Name'}
					{...form.getInputProps('lastName')}
				/>
				<TextInput mb='sm' label={'Email'} {...form.getInputProps('email')} />
				<UsersMultiSelect
					errorText='A role is required.'
					data={roles}
					labelText={'Roles'}
					placeholder='Select Roles'
					name={'roles'}
					form={form}
				/>
				<Group mt={'md'} position={'right'}>
					<Button radius={'md'} type='submit' loading={loading}>
						Save
					</Button>
				</Group>
			</form>
		</Modal>
	);
}
