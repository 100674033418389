import React from 'react';
import {
	Menu,
	Button,
	Avatar,
	Group,
	Center,
	useMantineTheme,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { useRecoilValue } from 'recoil';
import { user } from '../../Atoms/userAtoms';

import { useNavigate } from 'react-router';
import { swapBackgroundColor } from '../../_utils/handy-functions';

type Props = {
	logout: React.MouseEventHandler<HTMLButtonElement>;
};

export const AvatarHeaderWithMenu = ({ logout }: Props) => {
	const theme = useMantineTheme();
	const userInfo = useRecoilValue(user);
	const rolesForAddToSlack = ['admin', 'superadmin'];

	const showAddToSlack = () =>
		userInfo.roles.some((role: string) => rolesForAddToSlack.includes(role));

	const handleAddToSlack = () => {
		window.open(
			'https://slack.com/oauth/v2/authorize?client_id=2971454907714.3766463750914&scope=app_mentions%3Aread%2Cchannels%3Amanage%2Cchat%3Awrite.customize%2Cchat%3Awrite%2Cgroups%3Ahistory%2Cgroups%3Aread%2Cgroups%3Awrite%2Cim%3Aread%2Cim%3Awrite%2Cusers%3Aread.email%2Cusers%3Aread%2Cusers.profile%3Aread&user_scope=chat%3Awrite&state=%7B%22organizationID%22%3A%22${orgID}%22%7D',
			'_blank'
		);
	};
	let avatarImageURL =
		'urls' in userInfo ? userInfo?.urls[0] : `${userInfo.userAvatarURL}-48.png`;
	avatarImageURL = swapBackgroundColor(avatarImageURL, theme.fn.primaryColor());

	const avatarInitials =
		userInfo.firstName && userInfo.lastName
			? `${userInfo?.firstName?.substring(0, 1)}${userInfo?.lastName?.substring(
				0,
				1
			)}`
			: '?';

	return (
		<Menu
			zIndex={1000000}
			width='350px'
			position='bottom-end'
			withArrow
			styles={{ dropdown: { zIndex: 10000, position: 'fixed' } }}
		>
			<Menu.Target>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						cursor: 'pointer',
						zIndex: 100000,
					}}
				>
					<Group position={'apart'} noWrap spacing={'xs'}>
						<Avatar
							variant={'filled'}
							src={avatarImageURL}
							radius={'xl'}
							color={'lime'}
							children={
								<Center mb={1} mr={3}>
									{avatarInitials}
								</Center>
							}
						/>
						<FontAwesomeIcon icon={faAngleDown} size={'1x'} />
					</Group>
				</div>
			</Menu.Target>
			<Menu.Dropdown style={{ padding: '2%', zIndex: 1000000 }}>
				<Menu.Label style={{ textDecoration: 'underline' }}>
					{userInfo?.email}
				</Menu.Label>
				<Menu.Item>
					<Button
						onClick={logout}
						style={{ width: '100%' }}
						variant={'filled'}
						color={'red'}
					>
						Log out
					</Button>
				</Menu.Item>
				<Menu.Label>v{process.env.REACT_APP_VERSION}</Menu.Label>
			</Menu.Dropdown>
		</Menu>
	);
};
