import React, { Dispatch, useEffect } from 'react';
import {
	Button,
	Checkbox,
	Group,
	ScrollArea,
	Stack,
	Text,
} from '@mantine/core';
import { RequestNotificationCard } from '../Popovers/ShareMeetingPopover/components/RequestNotificationCard';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { user as userAtom } from '../../Atoms/userAtoms';
import {
	MeetingRequestNotification,
	notifications as notificationsAtom,
} from '../../Atoms/notifications';
import { useListState } from '@mantine/hooks';
import { InvitedUser } from '../../interfaces/user';
import { updateMeetingUserLink } from '../../api/api';
import { logger } from '../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { currentMeetingDistributionList } from '../../Atoms/meetingAtom';

interface Props {
	notifications: MeetingRequestNotification[];
}
export const NotificationList = ({ notifications }: Props) => {
	const currentUser = useRecoilValue(userAtom);
	const setNotifications = useSetRecoilState(notificationsAtom);
	const setViewers = useSetRecoilState(currentMeetingDistributionList);

	const [checkboxValues, handlers] = useListState(
		notifications.map((notification, index) => ({
			checked: false,
			// label: notification?.userlink?.user?.email,
			value: { ...notification, index },
			key: notification?.id,
		}))
	);

	const allChecked = checkboxValues.every((value) => value.checked);
	const indeterminate =
		checkboxValues.some((value) => value.checked) && !allChecked;
	const amountChecked = checkboxValues.filter((value) => value.checked).length;

	useEffect(() => {
		handlers.setState(
			notifications.map((notification, index) => ({
				checked: false,
				// label: notification?.userlink?.user?.email,
				value: { ...notification, index },
				key: notification?.id,
			}))
		);
	}, [notifications]);

	const updateRequest = async (
		user: MeetingRequestNotification,
		status,
		showNotification = true
	) => {
		try {
			// approve request by setting user meeting link status to active
			const res = await updateMeetingUserLink(
				user?.id,
				status,
				currentUser.currentOrganizationID
			);
			setNotifications((prev) =>
				prev.filter((viewer) => {
					return !(
						viewer.id === user.id && viewer.meetingID === user.meetingID
					);
				})
			);
			setViewers((prev) =>
				prev.map((viewer) => {
					if (
						viewer.id === user.id &&
						viewer.userlink.meetingID === user.meetingID
					) {
						return {
							...viewer,
							userlink: res?.data?.data,
						};
					}
					return viewer;
				})
			);
			logger('info', 'request updated', res.data.data);
			showNotification &&
				showSuccessNotification({
					message: 'Request was successfully updated.  Great work!',
				});
			return res?.data?.data?.id;
		} catch (error) {
			console.log('error', error);
			logger('error', 'failed to update request', error);
			showNotification &&
				showFailureNotification({
					message:
						'Apologies, but we were unable to update your request at this time. Please try again or contact support for assistance.',
				});
		}
	};

	const bulkUpdateUserRequests = async (checkboxValues, status) => {
		try {
			const response = await Promise.allSettled(
				checkboxValues.map((value) => updateRequest(value.value, status, false))
			);
			const successful = response
				.filter((res) => res.status === 'fulfilled')
				.map((res: any) => res.value);
			showSuccessNotification({
				message: `Bulk action request action ${successful.length} of ${checkboxValues.length} successful. Great job!`,
			});
		} catch (error) {
			console.log('error', error);
			logger('error', 'failed to update request', error);
			showFailureNotification({
				message:
					'Apologies, but we were unable to update your request at this time. Please try again or contact support for assistance.',
			});
		}
	};

	return (
		<>
			{notifications.length ? (
				<Group position={'apart'} mb={'md'} spacing={'xs'} pl={'sm'}>
					<Checkbox
						size={'sm'}
						checked={allChecked}
						indeterminate={indeterminate}
						transitionDuration={0}
						onChange={() =>
							handlers.setState((current) =>
								current.map((value) => ({ ...value, checked: !allChecked }))
							)
						}
					/>
					<Group>
						<Button
							disabled={!amountChecked}
							size={'xs'}
							variant={'light'}
							color={'violet'}
							onClick={() =>
								bulkUpdateUserRequests(
									checkboxValues.filter((item) => item.checked),
									'active'
								)
							}
						>
							{amountChecked ? `Approve (${amountChecked})` : 'Approve'}
						</Button>
						<Button
							disabled={!amountChecked}
							size={'xs'}
							variant={'light'}
							onClick={() =>
								bulkUpdateUserRequests(
									checkboxValues.filter((item) => item.checked),
									'denied'
								)
							}
						>
							{amountChecked ? `Deny (${amountChecked})` : 'Deny'}
						</Button>
					</Group>
				</Group>
			) : null}
			<ScrollArea
				h={notifications.length ? 500 : 100}
				offsetScrollbars
				scrollbarSize={10}
				type={'always'}
				styles={(theme) => ({
					scrollbar: {
						'&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
							backgroundColor: theme.colors['primary'][0],
						},
						right: 10,
					},
				})}
			>
				<Stack h={'100%'} spacing={0}>
					{notifications.length ? (
						notifications.map((user, index) => (
							<RequestNotificationCard
								checkboxValue={checkboxValues[index]}
								index={index}
								viewingMeeting={false}
								requestingUser={user}
								handlers={handlers}
								updateRequest={updateRequest}
							/>
						))
					) : (
						<Text align={'center'}>No new requests.</Text>
					)}
				</Stack>
			</ScrollArea>
		</>
	);
};
