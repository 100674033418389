import React, { useEffect, useState } from 'react';
import {
	Button,
	Center,
	createStyles,
	Group,
	Text,
	TextInput,
	Title,
	Stack,
	Modal,
	Space,
	Loader,
	Image,
	Divider,
	PinInput,
} from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	authTokenExpirationTime,
	isAuthenticated,
	originalURL,
	reelayLoader,
	userAuthenticated as userAuthenticatedAtom,
} from '../../Atoms/auth';
import { useForm } from '@mantine/form';
import { ReelayIcon } from '../../components/ReelayIcon';
import { useNavigate } from 'react-router';
import CreateUserModal from '../../components/create_user_modal';
import {
	currentUser,
	user as UserAtom,
	userOrganizationMembers,
	userOrganization as OrganizationAtom,
} from '../../Atoms/userAtoms';
import { useSearchParams } from 'react-router-dom';
import {
	authenticateWithMicrosoft,
	getCurrentOrganization,
	getCurrentUser,
	getUserByID,
	getUsersByOrg,
	login,
	loginWithTheCode,
	sendAuthCode,
} from '../../api/api';
import { isAdmin, isSupportUser } from '../../helpers/auth';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
	microsoftIcon,
	privacyLink,
	termsLink,
} from '../../components/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { ReelayLoader } from '../../components/ReelayLoader';
import { getCurrentYear } from '../../helpers/data';
import { logger } from '../../helpers/logger';
import { getOrganizationBrand } from '../../api/settings';
import { isCustomBrandedAtom, organizationBrand } from '../../Atoms/settings';
import { Logo } from '../../components/reelayLogo';
import { loginWithMicrosoft } from '../../helpers/sso';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { ensureActiveDirectoryCustomRolesExist } from '../../api/sso';
import OTPInput from './components/OTPInput';

const useStyles = createStyles((theme) => ({
	page: {
		height: '100svh',
		verticalAlign: 'center',
	},
	label: {
		textAlign: 'center',
		width: '352px',
	},

	controls: {
		[theme.fn.smallerThan('xs')]: {
			flexDirection: 'column-reverse',
		},
		width: '352px',
	},

	control: {
		[theme.fn.smallerThan('xs')]: {
			width: '100%',
			textAlign: 'center',
		},
		width: '352px',
		height: '42px',
		fontSize: '16px',
	},

	copy: {
		fontSize: '12px',
		lineHeight: '22px',
		color: theme.colors?.primary[5],
	},

	welcome: {
		fontSize: '32px',
		fontWeight: 'normal',
		lineHeight: '28px',
		marginTop: '40px',
	},
}));

// eslint-disable-next-line no-extend-native,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-extend-native
Array.prototype.contains = function (element: string) {
	return this.indexOf(element) > -1;
};

// export const dummyBrandData = {
//   name: 'Walmart',
//   color_primary: 'blue',
//   color_secondary: 'yellow',
//   color_level_primary: 5,
//   color_level_secondary: 5,
//   logo_url: 'https://corporate.walmart.com/_download?id=00000174-4657-def6-ab7f-e657c9910000',
//   icon: 'https://www.conewangocreek.org/wp-content/uploads/2015/04/walmart-logo-blue-gold-300x300.png',
//   // subdomain: 'reelay.walmart.com'
// };

export const LoginPage = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [authCodeSent, setAuthCodeSent] = useState(false);
	const [showErrorPage, setShowErrorPage] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [loggingIn, setLoggingIn] = useRecoilState(reelayLoader);
	const [loading, setLoading] = useState(false);
	const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);
	const setCurrentUser = useSetRecoilState(UserAtom);
	const setOrganization = useSetRecoilState(OrganizationAtom);
	const setAuthStatus = useSetRecoilState(userAuthenticatedAtom);
	const setAuthTokenExpirationTime = useSetRecoilState(authTokenExpirationTime);
	const setOrganizationMembers = useSetRecoilState(userOrganizationMembers);
	const originalUrl = useRecoilValue(originalURL);
	const [brand, setBrand] = useRecoilState(organizationBrand);
	// use usesearchparams? to change to login?token=
	const [searchParams] = useSearchParams();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const currentParams = Object.fromEntries([...searchParams]);
	const user = useRecoilValue(currentUser);
	// const magicToken = currentParams.magicToken;
	const magicToken = window['magicToken'];
	const organizationID = currentParams.organizationID;
	const userAuthenticated = useRecoilValue(isAuthenticated);
	const isCustomBranded = useRecoilValue(isCustomBrandedAtom);
	const showCreateAccountButton = originalUrl.includes('createAccount=true');

	const form = useForm({
		initialValues: {
			email: '',
		},
	});

	const authForm = useForm({
		initialValues: {
			authCode: '',
		},
		validate: {
			authCode: (value) => {
				const regex = /^[0-9]+$/;
				if (!regex.test(value) || value.length !== 6) {
					return 'Please enter a valid 6-digit code.';
				}
			},
		},
	});

	useEffect(() => {
		const loadOrganizationSettings = async () => {
			try {
				if (organizationID) {
					// attempt to fetch branded organization settings.  If it fails, then we will just use the default.
					const organizationBrand = await getOrganizationBrand(organizationID);
					// set state for organization settings
					if (organizationBrand) {
						const brandDetails = organizationBrand.value;
						setBrand(brandDetails);
						// set in local storage to be used inside theme object (recoil state limited to function component).
						localStorage.setItem('brand', JSON.stringify(brandDetails));
					} else {
						setBrand({});
						// set in local storage to be used inside theme object (recoil state limited to function component).
						localStorage.setItem('brand', JSON.stringify({}));
					}
				} else {
					setBrand({});
					// set in local storage to be used inside theme object (recoil state limited to function component).
					localStorage.setItem('brand', JSON.stringify({}));
				}
			} catch (err) {
				logger('error', 'Error getting custom branding', err);
			}
		};

		const loadSettingsAndLogin = async () => {
			try {
				await loadOrganizationSettings();
				if (magicToken) {
					const res = await handleLoginLogic();
					logger('info', 'Login was successful', res);
				} else if (userAuthenticated) {
					const redirectURL = localStorage.getItem(
						'microsoft_sso_setup_redirect_url'
					);
					if (!redirectURL) {
						navigate(isAdmin(user?.roles) ? '/admin/meetings' : '/meetings');
					}
				}
			} catch (err) {
				logger('error', 'Error loading page', err);
			} finally {
				setPageLoading(false);
			}
		};

		loadSettingsAndLogin();

		const loginWithMicrosoft = async () => {
			const redirectURL = localStorage.getItem(
				'microsoft_sso_setup_redirect_url'
			);
			try {
				// microsoft oauth login
				if (currentParams?.state) {
					setLoading(true);
					const state = JSON.parse(currentParams.state);
					if (state.type === 'microsoft_oauth') {
						// take code and authenticate with code
						const res = await authenticateWithMicrosoft(currentParams.code);
						if (redirectURL) {
							ensureActiveDirectoryCustomRolesExist();
						}
						const authData = res?.data?.data;
						if (authData?.sessionToken) {
							setAuthStatus(true);
						} else {
							showFailureNotification({
								message:
									'Sorry, there was an error logging in with Microsoft. Please try again or another method.',
							});
							logger('error', 'Error logging in with Microsoft', res);
						}

						handleLoginLogic(true, redirectURL)
							.then(() => {
								logger('info', 'Login was successful', res);
							})
							.catch((err) => logger('error', 'Error logging in', err))
							.finally(() => setLoading(false));
					}
				}
			} catch (err) {
				navigate(redirectURL);
				localStorage.removeItem('microsoft_sso_setup_redirect_url');
			} finally {
				setLoading(false);
			}
		};

		loginWithMicrosoft();
	}, [organizationID]);

	const handleLoginLogic = async (skipLoginUser = false, redirectURL = '') => {
		try {
			setLoggingIn(true);
			if (!skipLoginUser) await loginUser(magicToken);
			const currentUserRequest = await handleGetCurrentUser();
			logger('info', 'Current User Request', currentUserRequest);
			const { id, email, firstName, lastName } = currentUserRequest;

			if (!currentUserRequest.roles.contains('support' || 'masteradmin')) {
				await handleGetCurrentOrganization(
					currentUserRequest.currentOrganizationID
				);
			}
			logger('info', 'Login was successful', currentUserRequest);
			const supportUser = isSupportUser(currentUserRequest.roles);

			let startingPath =
				typeof originalUrl === 'string' &&
				!['/login', '/signup', '', '/', 'new-account'].includes(originalUrl)
					? originalUrl.length > 1
						? originalUrl
						: '/meetings'
					: supportUser
					? '/admin/meetings'
					: '/meetings';

			// final check if the starting path does not include keyword meeting then redirect to base start.
			if (
				!startingPath.includes('meeting') &&
				!startingPath.includes('access-request') &&
				!startingPath.includes('share')
			) {
				startingPath = supportUser ? '/admin/meetings' : '/meetings';
			}
			logger('info', 'Starting path', startingPath);
			navigate(redirectURL ? redirectURL : startingPath);
		} catch (err) {
			logger('error', 'Error logging in', err);
		} finally {
			setLoggingIn(false);
		}
	};

	// this Function no longer needs to be async because it is now a promise waiting for a response before leaving the helper
	// the .then() takes two items - what to do onFulfilled and onRejected

	const handleSendAuthCode = (email: string) => {
		setLoading(true);
		try {
			sendAuthCode(email)
				.then((response) => {
					if (response?.success) {
						setLoading(false);
						setAuthCodeSent(true);
						showSuccessNotification({
							message:
								'Check your email for a one-time password to login to Reelay.',
						});
						logger('info', 'One time password sent was successful', response);
						return response;
					} else {
						logger('error', 'Error sending OTP', response);
						setLoading(false);
						showFailureNotification({
							message:
								'Sorry, there was an error sending the one-time password. Please try again.',
						});
						setShowErrorPage(true);
					}
				})
				.catch((err) => {
					logger('error', 'Error sending OTP', err);
					setLoading(false);
					showFailureNotification({
						message:
							'Sorry, there was an error sending the one-time password. Please try again.',
					});
				});
		} catch (err) {
			logger('error', 'Error sending OTP', err);
		}
	};

	const handleAuthCode = async (values) => {
		try {
			setVerifyButtonLoading(true);
			const res = await loginWithTheCode(values.authCode, form.values.email);
			console.log('res', res);
			if (res instanceof Error) {
				showFailureNotification({
					title: 'Verification code failed!',
					message: res.message,
				});
				return res;
			}
			setAuthStatus(true);
			setAuthTokenExpirationTime(res?.expiresAt);
			showSuccessNotification({
				message: 'You were logged in!',
			});
			const { organizationID } = res;
			const currentUserRequest = await handleGetCurrentUser(organizationID);
			logger('info', 'Current User Request', currentUserRequest);
			const { id, email, firstName, lastName, currentOrganizationID } =
				currentUserRequest;
			console.log('currentOrganizationID', currentOrganizationID);

			// set brand if it exists
			const brandData = await getOrganizationBrand(currentOrganizationID);
			if (brandData?.value?.color_primary) {
				setBrand(brandData.value);
				localStorage.setItem('brand', JSON.stringify(brandData.value));
			}

			if (!currentUserRequest.roles.contains('support' || 'masteradmin')) {
				await handleGetCurrentOrganization(
					currentUserRequest.currentOrganizationID
				);
			}
			logger('info', 'Login was successful', currentUserRequest);
			const urlPath = originalUrl.split('?')[0]; // This will split the URL at the "?" and take the first part, effectively removing the query parameters.

			// if original URL does not contain /admin and they are support user then take to the /admin/meetings landing page
			// if original URL contains /admin and they are not a suppoert user, then take them to the /meetings landing page
			const isSupport = isSupportUser(currentUserRequest.roles);
			const validOriginalURL =
				typeof originalUrl === 'string' &&
				!['/login', '/signup', '', '/', 'new-account'].includes(urlPath) &&
				originalUrl.length > 1;

			const startingPath =
				(validOriginalURL && urlPath.includes('admin') && isSupport) ||
				(validOriginalURL && !urlPath.includes('admin') && !isSupport)
					? originalUrl
					: isSupport
					? '/admin/meetings'
					: '/meetings';
			logger('info', 'Starting path', startingPath);
			navigate(startingPath);
			return;
		} catch (err) {
			logger('error', 'Error logging in user', err);
			showFailureNotification({
				message: 'There was an error logging you in. Please try again.',
			});
		} finally {
			setVerifyButtonLoading(false);
		}
	};

	const handleGetCurrentUser = async (organizationID = '') => {
		try {
			const user = await getCurrentUser(organizationID);

			setLoading(false);
			setCurrentUser(user);

			const { currentOrganizationID } = user;
			console.log('getUserByOrgs 6 FIRES ---------------------------------');
			const usersResponse = await getUsersByOrg(currentOrganizationID);
			const users = usersResponse;
			setOrganizationMembers(users);

			return user;
		} catch (err) {
			logger('error', 'Error getting current user', err);
		}
	};

	const handleGetCurrentOrganization = async (organizationID: string) => {
		try {
			const currentOrg = await getCurrentOrganization(organizationID);
			setOrganization(currentOrg.data.data);
			return currentOrg.data.data;
		} catch (err) {
			logger('error', 'Error getting current organization', err);
		}
	};

	const loginUser = async (magicToken: string) => {
		try {
			setLoading(true);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const loginResponse = await login({ magicToken });
			setLoading(false);
			setAuthStatus(true);
			showSuccessNotification({
				message: 'You are logged in!',
			});

			return loginResponse.data.data;
		} catch (err) {
			logger('error', 'Error logging in user', err);
			showFailureNotification({
				message: 'There was an error logging you in. Please try again.',
			});
			return;
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{pageLoading ? (
				<Center className={classes.page}>
					<Loader size={200} />
				</Center>
			) : (
				<Center
					className={classes.page}
					sx={(theme) => ({
						background: isCustomBranded
							? `linear-gradient(180deg, ${theme.colors.secondary[1]} 0%, ${theme.colors.gray[0]} 100%)`
							: 'linear-gradient(180deg, rgba(79, 0, 245, 0.12) 0%, rgba(0, 128, 245, 0.12) 100%)',
					})}
				>
					<Stack h={'100%'} justify={'space-between'} py={'lg'}>
						<Space h='xl' />
						<Space h='xl' />

						<Stack align={'center'}>
							{/*Logo*/}
							{loggingIn ? (
								<>{isCustomBranded ? <Loader size={50} /> : <ReelayLoader />}</>
							) : (
								<>
									<ReelayIcon src={brand?.logo_url} />
								</>
							)}

							{/*Header*/}
							{!authCodeSent ? (
								<>
									{!brand?.name ? (
										<Title
											align={'center'}
											order={1}
											weight={400}
											color={'primary-text'}
										>
											Welcome to Reelay!
										</Title>
									) : null}
								</>
							) : null}

							{authCodeSent ? (
								<Center>
									<Group spacing={'xl'}>
										<OTPInput
											form={form}
											authForm={authForm}
											resendButtonLoading={loading}
											verifyButtonLoading={verifyButtonLoading}
											handleAuthCode={handleAuthCode}
											handleSendAuthCode={handleSendAuthCode}
											handleNavigateBack={() => setAuthCodeSent(false)}
										/>
									</Group>
								</Center>
							) : showErrorPage ? (
								<Center>
									<Stack align={'center'}>
										<Title order={3} weight={400} maw={400}>
											Looks like we don't have an account for{' '}
											<Text
												span
												// variant={'gradient'}
												// gradient={{
												//   from: '#6200DE',
												//   to: '#1400FF',
												//   deg: 45,
												// }}
												color={'primary'}
												inherit
											>
												{form.values.email}
											</Text>
											{'. '}
											{isCustomBranded
												? 'Please reach out to your company’s administrator for assistance in logging in.'
												: `Reach out to your company’s administrator or create a new ${
														showCreateAccountButton ? 'account' : 'organization'
												  }.`}
										</Title>
										<Button
											className={classes.control}
											radius={'md'}
											onClick={() => {
												setShowErrorPage(false);
												setAuthCodeSent(false);
											}}
										>
											Try a different email
										</Button>
										{isCustomBranded ? (
											<Text></Text>
										) : (
											<Button
												variant={'outline'}
												className={classes.control}
												radius={'md'}
												onClick={() =>
													navigate(
														showCreateAccountButton ? '/new-account' : '/signup'
													)
												}
											>
												{showCreateAccountButton
													? 'Create an account'
													: 'Create an organization'}
											</Button>
										)}
									</Stack>
								</Center>
							) : !loggingIn ? (
								<Center>
									<Group spacing={'xl'}></Group>
									<form
										onSubmit={form.onSubmit((values) =>
											handleSendAuthCode(values.email)
										)}
									>
										<Stack spacing={0}>
											<TextInput
												placeholder='Enter your email to login'
												className={classes.label}
												required
												{...form.getInputProps('email')}
												radius='md'
												size='lg'
											/>
											<Group
												position='right'
												mt='xl'
												mb='md'
												className={classes.controls}
											>
												<Button
													type='submit'
													className={classes.control}
													loading={loading}
													leftIcon={
														<FontAwesomeIcon
															style={{ cursor: 'pointer', float: 'right' }}
															icon={faGlobe}
														/>
													}
													rightIcon={<></>}
													styles={{
														inner: {
															display: 'flex',
															justifyContent: 'space-between',
														},
													}}
													radius={'md'}
												>
													<Text ml={'sm'}>Login</Text>
												</Button>
											</Group>
											{brand?.sso ? (
												<>
													<Divider
														labelPosition={'center'}
														label={
															<Text
																size={'lg'}
																align={'center'}
																color={'secondary-text'}
															>
																OR
															</Text>
														}
													/>

													<Button
														onClick={loginWithMicrosoft}
														variant={'outline'}
														className={classes.control}
														loading={loading}
														leftIcon={
															<Image
																maw={25}
																radius='md'
																src={microsoftIcon}
																alt='Microsoft logo'
															/>
														}
														rightIcon={<></>}
														radius={'md'}
														styles={{
															inner: {
																display: 'flex',
																justifyContent: 'space-between',
															},
														}}
													>
														Sign In with Microsoft
													</Button>
												</>
											) : null}
										</Stack>
									</form>
								</Center>
							) : null}
						</Stack>

						<Space h={'sm'} />

						{/*Footer*/}
						<Stack className={classes.copy} align={'center'}>
							<Text color={'primary-text'}>
								v{process.env.REACT_APP_VERSION}
							</Text>
							{isCustomBranded ? (
								<Stack spacing={0}>
									<Text color={'primary-text'} align={'center'}>
										powered by
									</Text>
									<Logo width={60} showReelayLogo={true} showWoodmark={true} />
								</Stack>
							) : null}
							<Text color={'primary-text'}>
								Copyright © {getCurrentYear()} Reelay Meetings, Inc.
							</Text>
							<Text>
								<a
									style={{
										textDecoration: 'none',
										color: 'black',
										fontWeight: 'bold',
									}}
									rel={'noopener noreferrer'}
									target={'_blank'}
									href={termsLink}
								>
									Terms of Use
								</a>{' '}
								|{' '}
								<a
									style={{
										textDecoration: 'none',
										color: 'black',
										fontWeight: 'bold',
									}}
									rel={'noopener noreferrer'}
									target={'_blank'}
									href={privacyLink}
								>
									{' '}
									Privacy Policy
								</a>
							</Text>
						</Stack>
					</Stack>
				</Center>
			)}
		</>
	);
};
