import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';
import { showFailureNotification } from '../helpers/notifications';

// Function to create a contact in HubSpot
export const createHubSpotContact = async (firstName, lastName, email) => {
	const contactData = {
		firstname: firstName,
		lastname: lastName,
		email: email,
	};

	try {
		const response = await axiosInstance.post(
			`v1/hubspot/contact`,
			contactData
		);

		console.log('Contact created in HubSpot:', response);

		logger('info', 'Contact created in HubSpot:', response);
		return response.data;
	} catch (error) {
		console.log('Failed to create contact in HubSpot:', error);
		logger('error', 'Failed to create contact in HubSpot:', error);
		showFailureNotification({
			title: 'Failed to create user',
			message: error?.response?.data || 'An error occurred.',
		});
		throw error;
	}
};

// Function to create a company in HubSpot
export const createHubSpotCompany = async ({
	name,
	domain,
	industry,
	numberOfEmployees,
	additionalDomains,
}) => {
	const companyData = {
		name: name,
		domain: domain,
		industry: industry,
		numberofemployees: numberOfEmployees,
		additionalDomains,
	};

	try {
		const response = await axiosInstance.post(
			`v1/hubspot/company`,
			companyData
		);

		console.log('Company created in HubSpot:', response);

		logger('info', 'Company created in HubSpot:', response);
		return response.data;
	} catch (error) {
		console.log('Failed to create company in HubSpot:', error);
		logger('error', 'Failed to create company in HubSpot:', error);
		throw error;
	}
};

// Function to create an association between a contact and a company in HubSpot
export const createHubSpotAssociation = async (contactId, companyId) => {
	const associationData = {
		companyId,
	};

	try {
		const response = await axiosInstance.put(
			`v1/hubspot/contact/${contactId}/associate-company`,
			associationData
		);

		console.log('Association created in HubSpot:', response);

		logger('info', 'Association created in HubSpot:', response);
		return response.data;
	} catch (error) {
		console.log('Failed to create association in HubSpot:', error);
		logger('error', 'Failed to create association in HubSpot:', error);
		throw error;
	}
};

// Function to check if a company exists in HubSpot by domain and name
export const checkHubSpotCompanyExists = async (name, domain) => {
	const companyData = {
		name,
		domain,
	};

	try {
		const response = await axiosInstance.post(
			`v1/hubspot/company/check-exists`,
			companyData
		);

		console.log('Company existence check in HubSpot:', response);

		logger('info', 'Company existence check in HubSpot:', response);
		return response.data;
	} catch (error) {
		console.log('Failed to check company existence in HubSpot:', error);
		logger('error', 'Failed to check company existence in HubSpot:', error);
		throw error;
	}
};
