import React, { useState } from 'react';
import {
	Modal,
	Button,
	Group,
	Stack,
	Text,
	List,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilState } from 'recoil';
import {
	User,
	UserStats,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { logger } from '../../../helpers/logger';
import { removeUser } from '../../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

interface Props {
	member: User;
	removingFromAllOrganizations: boolean;
	statsByOrganizationID: { [organizationID: string]: UserStats };
	opened: boolean;
	close: () => void;
	transfer: () => void;
}

const platformLabels = {
	google_calendar: 'Google Calendar',
	microsoft_outlook: 'Microsoft Outlook',
};

export default function DeleteUserModal({
	member,
	removingFromAllOrganizations,
	statsByOrganizationID,
	opened,
	close,
	transfer,
}: Props) {
	const [users, setUsers] = useRecoilState(userOrganizationMembers);
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: { email: '' },
		validate: {
			email: (value) =>
				value === member.email ? null : 'Email does not match',
		},
	});

	const handleDeleteUser = async () => {
		setLoading(true);
		try {
			const organizationID = removingFromAllOrganizations
				? undefined
				: member.currentOrganizationID;
			const response = await removeUser(member.id, organizationID);

			if (response?.data?.data?.success) {
				setUsers(users.filter((user) => user.id !== member.id));
				showSuccessNotification({
					message: 'User was successfully deleted.',
				});
				close();
			} else {
				showFailureNotification({
					message: 'Error deleting user. Please try again.',
				});
			}
		} catch (error) {
			logger('error', 'Error deleting user:', error);
			showFailureNotification({
				message: 'An error occurred. Please try again.',
			});
		} finally {
			setLoading(false);
			form.reset();
		}
	};

	const handleTransfer = () => {
		form.reset();
		close();
		transfer();
	};

	const renderUserStatsList = () => {
		if (!opened) return null;

		const renderStats = (organizationID) => (
			<List withPadding={removingFromAllOrganizations} listStyleType='circle'>
				{Object.entries(
					statsByOrganizationID[organizationID].countByStatus
				).map(([status, count]) => (
					<List.Item key={status}>
						{count} {status} meetings
					</List.Item>
				))}
				{statsByOrganizationID[organizationID].calendarPlatforms.map(
					(platform) => (
						<List.Item key={platform}>
							1 connected {platformLabels[platform] || platform}
						</List.Item>
					)
				)}
			</List>
		);

		return (
			<List size='sm'>
				{removingFromAllOrganizations
					? Object.keys(statsByOrganizationID).map((orgID) => (
							<List.Item key={orgID}>
								{statsByOrganizationID[orgID].name}
								{renderStats(orgID)}
							</List.Item>
					  ))
					: renderStats(member.currentOrganizationID)}
			</List>
		);
	};

	return (
		<Modal
			opened={opened}
			centered
			onClose={close}
			title='Are you sure?'
			size='lg'
		>
			<form onSubmit={form.onSubmit(handleDeleteUser)}>
				<Stack spacing='md'>
					<Text fw={500}>
						Email:{' '}
						<Text span fs='italic'>
							{member.email}
						</Text>
					</Text>
					<Text size='sm'>
						{removingFromAllOrganizations
							? 'Removing a user from all organizations will result in data loss.'
							: 'Removing a user from an organization will result in data loss.'}
						<br />
						This user currently has:
					</Text>
					{renderUserStatsList()}
					<Text size='sm'>
						You may transfer these meetings to another user to prevent data
						loss. To proceed with transferring, click "Transfer" below, then
						remove the user.
					</Text>
					<Text size={'sm'} fw={500} fs={'underline'}>
						This action cannot be undone.
					</Text>
					<Text size='sm'>Type the user’s email below to confirm:</Text>
					<TextInput
						placeholder='Enter email here'
						{...form.getInputProps('email')}
						required
						data-autofocus
					/>
					<Group position='apart' mt='md'>
						<Button type='submit' color='red' loading={loading}>
							Delete All Data
						</Button>
						<Group>
							<Button variant='outline' onClick={close}>
								Keep User
							</Button>
							<Button variant='filled' color='violet' onClick={handleTransfer}>
								Transfer
							</Button>
						</Group>
					</Group>
				</Stack>
			</form>
		</Modal>
	);
}
