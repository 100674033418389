import React from 'react';
import { Image } from '@mantine/core';
import { reelayLogo } from '../constants';

interface Props {
	src?: string;
}

export const ReelayIcon = ({ src = '' }: Props) => {
	const defaultStyling = {
		width: 100,
		marginLeft: 'auto',
		marginRight: 'auto',
	};
	return (
		// this works with walmart rectangular logo shape. We will need to standardize logo size somehow.
		<div
			style={
				src
					? {
							width: 'auto',
							maxWidth: 250,
							marginLeft: 'auto',
							marginRight: 'auto',
					  }
					: defaultStyling
			}
		>
			<Image radius='md' src={src ? src : reelayLogo} alt='Company Logo' />
		</div>
	);
};
