import { getTeams } from '../api/api';

export const fetchMembers = async (userData: [], organizationID: string) => {
	// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// // @ts-ignore
	let formattedMembers: {
		value: string | string[];
		label: string;
		group: string;
		id: string;
		key: string;
	}[] = [];

	formattedMembers = [...formattedMembers, ...userData];

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	console.log('fetchMembers getTeams organizationID', organizationID);
	const teams = await getTeams(organizationID);
	//  loop through teams, add to teamData
	teams.data.data.forEach((team: any, index: number) => {
		//loop through users array and add here
		const tempUserArr: any[] = [];
		team.users.forEach((user: any) => {
			tempUserArr.push(user.email);
		});
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		formattedMembers.push({
			value: JSON.stringify(tempUserArr),
			label: team.name,
			group: 'Teams',
			id: team.id,
			key: team.id,
		});
	});
	return formattedMembers;
};

export const flattenValidateAndFilterInvitees = (invitees: string[]) => {
	return invitees
		.map((str) => {
			// the array of member emails in a team is stringified earlier.
			// need to check for teams and parse back into array.
			if (str[0] === '[' && str[str.length - 1] === ']') {
				return JSON.parse(str);
			}
			return str;
		})
		.flat()
		.filter(
			(email: string, idx: number, arr: string[]) =>
				arr.indexOf(email) === idx && email.includes('@')
		);
};
